<template>
  <div class="teaser-captions">
    <div
      class="teaser-captions__caption"
      v-for="(caption, index) in teaserCaptions"
      :key="index"
      :class="[caption.position]"
      ref="caption"
    >
      <span v-html="CaptionBorder" class="border border--top" />
      <h2 v-for="line in caption.lines" :key="line" v-html="line" />
      <span v-html="CaptionBorder" class="border border--bottom" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap/gsap-core';
import { TEASER_CAPTIONS } from '@/core/constants';
import CaptionBorder from '@/assets/svg/captionBorder.svg';

export default {
  name: 'teaser-captions',
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    CaptionBorder,
    teaserCaptions: TEASER_CAPTIONS,
    captionsStarted: [],
    captionsCompleted: [],
    firstCaptionStart: false,
    secondCaptionStart: false,
    thirdCaptionStart: false,
    lastCaptionStart: false,
    firstCaptionComplete: false,
    secondCaptionComplete: false,
    thirdCaptionComplete: false,
    lastCaptionComplete: false,
    animInTL: null,
  }),
  watch: {
    time(val) {
      this.teaserCaptions.forEach((caption, index) => {
        if (this.captionsStarted.indexOf(index) < 0 && val >= caption.startTime) {
          this.captionsStarted.push(index);
          this.animCaptionIn(index);
        }
        if (
          this.captionsStarted.indexOf(index) > -1
          && this.captionsCompleted.indexOf(index) < 0
          && val >= caption.endTime
        ) {
          this.captionsCompleted.push(index);
          this.animCaptionOut(index);
        }
      });
    },
  },
  methods: {
    animCaptionIn(index) {
      gsap.killTweensOf(this.animInTL);
      this.animInTL = null;
      const lines = this.$refs.caption[index].querySelectorAll('h2');
      const borders = this.$refs.caption[index].querySelectorAll('.border');
      this.animInTL = gsap.timeline();
      gsap.to(lines, {
        opacity: 1,
        stagger: 0.75,
        duration: 1,
        ease: 'power1.out',
      });
      gsap.to(borders, { opacity: 1, delay: lines.length - 0.25 });
    },
    animCaptionOut(index) {
      gsap.to(this.$refs.caption[index], {
        opacity: 0,
        duration: 1,
        ease: 'power1.out',
      });
    },
  },
};
</script>

<style lang="scss">
.teaser-captions {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  box-sizing: border-box;
  &__caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 100px;
    padding: 30px;
    left: 50%;
    transform: translateX(-50%);
    @include bpMedium {
      transform: translateX(0);
      bottom: 100px;
      &.left {
        left: 100px;
      }
      &.right {
        left: auto;
        right: 100px;
      }
    }
    .border {
      stroke: white;
      width: 100px;
      position: absolute;
      top: 15px;
      left: 15px;
      transform: translate(-25%, -50%);
      opacity: 0;
      &--bottom {
        top: auto;
        left: auto;
        bottom: 15px;
        right: 15px;
        transform: rotate(180deg) translate(-25%, -50%);
      }
    }
    h2 {
      white-space: nowrap;
      text-align: center;
      color: white;
      font-family: $bodyFont;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -1px;
      text-transform: none;
      opacity: 0;
      @include bpMedium {
        font-size: 26px;
        line-height: 30px;
      }
      @include bpLarge {
        font-size: 36px;
        line-height: 42px;
      }
    }
  }
}
</style>
