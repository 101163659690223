<template>
  <div class="audio-wave">
    <svg
      v-for="index in lineCount"
      viewBox="0 0 2 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref="lines"
      :key="index"
      :class="{ filled: index < linesFilled }"
    >
      <line
        x1="1"
        y1="0"
        x2="1"
        y2="100%"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </div>
</template>

<script>
import { gsap } from 'gsap/gsap-core';
import { Howl } from 'howler';

export default {
  name: 'audio-wave',
  props: {
    audioFile: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    lineCount: 40,
    linesFilled: 0,
    tweens: [],
    animationId: '',
    audioStartTime: 0,
    isPlaying: false,
    animationStartTime: 0,
    audioDuration: 0,
    audioCompletePercent: 0,
  }),
  mounted() {
    if (this.audioFile) {
      this.audio = new Howl({
        src: this.audioFile,
        loop: false,
        autoplay: false,
        onplay: this.animateLines,
        onend: this.onAudioEnd,
        onpause: this.stopLines,
        onload: this.handleCanPlay,
        onfade: this.pause,
      });
    }
  },
  beforeUnmount() { if (this.audio) this.audio.unload(); },
  methods: {
    fade() {
      this.audio.fade(1, 0, 750);
    },
    stop() {
      this.audio.stop();
      this.stopLines();
    },
    handleCanPlay() {
      this.$emit('audioLoaded');
    },
    play() {
      this.audio.volume(1);
      this.audio.play();
      this.isPlaying = true;
    },
    pause() {
      this.audio.pause();
      this.$emit('pause');
      this.stopLines();
    },
    onAudioEnd() {
      this.$emit('ended');
      this.stopLines();
    },
    animateLines() {
      gsap.set(this.$refs.lines, { clearProps: 'opacity' });
      const currentTime = this.audio.seek();
      const duration = this.audio.duration();
      this.animationStartTime = Date.now();
      this.audioStartTime = currentTime;
      this.audioDuration = duration;
      this.updateAudioProgress();
      this.$refs.lines.forEach((line) => {
        gsap.to(line, gsap.utils.random(0.35, 0.5), {
          scaleY: gsap.utils.random(0.85, 1.05),
          delay: gsap.utils.random(0.05, 1),
          yoyo: true,
          repeat: -1,
        });
      });
    },
    stopLines() {
      this.isPlaying = false;
      cancelAnimationFrame(this.animationId);
      gsap.killTweensOf(this.$refs.lines);
      gsap.to(this.$refs.lines, 0.75, { scaleY: 0.1, opacity: 0.5 });
    },
    updateAudioProgress() {
      const elapsedTime = (Date.now() - this.animationStartTime) / 1000;
      const currentTime = elapsedTime + this.audioStartTime;
      if (currentTime <= this.audioDuration) {
        this.$emit('timeupdate', currentTime);
        const audioCompletePercent = Math.round((currentTime / this.audioDuration) * 100) / 100;
        this.linesFilled = Math.round(audioCompletePercent * this.lineCount);
        this.animationId = requestAnimationFrame(this.updateAudioProgress);
      }
    },
  },
};
</script>

<style lang="scss">
.audio-wave {
  display: flex;
  svg {
    height: 34px;
    width: 2px;
    margin: 0 1px;
    opacity: 0.5;
    overflow: visible;
    transform: scaleY(0.1);
    transition: opacity 250ms $easeOutMaterial;
    &.filled {
      opacity: 1;
    }
  }
  audio {
    position: absolute;
    z-index: -1;
    width: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
  }
}
</style>
