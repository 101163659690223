<template>
  <div class="caption-bar" :class="{ hidden: !shouldShowCaptions }">
    <p class="caption-bar__caption" v-html="caption" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import eventBus from '@/core/eventBus';
import { APP_EVENTS } from '@/core/constants';

export default {
  name: 'CaptionBar',
  data: () => ({
    captions: [],
    capCount: 0,
    caption: '',
    currentTime: 0,
    currentCaption: '',
  }),
  computed: {
    shouldShowCaptions() {
      return this.captionsOn && this.caption;
    },
    ...mapState(['captionsOn', 'viewWidth']),
  },
  created() {
    eventBus.on(APP_EVENTS.SET_ACTIVE_CAPTION, this.initCaptions);
    eventBus.on(APP_EVENTS.CAPTION_TIME_UPDATE, this.handleTimeUpdate);
    eventBus.on(APP_EVENTS.CLEAR_CAPTIONS, this.clearCaptions);
  },
  methods: {
    initCaptions(cap) {
      this.clearCaptions();
      this.currentCaption = cap;
      const lines = this.currentCaption.split('\n\n');
      lines.forEach((line, i) => {
        this.captions[i] = [];
        this.captions[i] = line.split('\n');
      });
    },
    clearCaptions() {
      this.captions = [];
      this.capCount = 0;
      this.caption = '';
      this.currentCaption = '';
    },
    handleTimeUpdate(time) {
      if (!this.captions.length) return;
      this.currentTime = time;
      if (!this.currentTime) return;
      const { captions } = this;
      let currentSub = '';
      if (this.subtitleInRange()) {
        captions[this.capCount].forEach((cap, i) => {
          let lineBreak = currentSub ? '<br>' : '';
          if (lineBreak && this.viewWidth < 650) lineBreak = ' ';
          if (i > 1) currentSub += `${lineBreak}${cap}`;
        });
      }
      if (this.hasNextTimeCode()) this.capCount += 1;
      this.caption = currentSub;
    },
    hasNextTimeCode() {
      const { captions, capCount, currentTime } = this;
      if (
        currentTime > this.timeCodeMax(captions[capCount][1])
        && capCount < (captions.length - 1)
      ) {
        return true;
      }
      return false;
    },
    subtitleInRange() {
      const { currentTime, capCount, captions } = this;
      if (
        currentTime > this.timeCodeMin(captions[capCount][1])
        && currentTime < this.timeCodeMax(captions[capCount][1])
      ) {
        return true;
      }
      return false;
    },
    timeCodeMin(tc) {
      return this.timeCodeToSecs(tc.split(' --> ')[0]);
    },
    timeCodeMax(tc) {
      return this.timeCodeToSecs(tc.split(' --> ')[1]);
    },
    timeCodeToSecs(tc) {
      const timeCode = tc.split(',')[0].split(':');
      const millis = tc.split(',')[1] / 1000;
      return Math.floor(timeCode[0] * 60 * 60)
        + Math.floor(timeCode[1] * 60) + Math.floor(timeCode[2]) + millis;
    },
    // Just in case seeking becomes needed. -MA
    //  handleSeek() {
    //   let { subCount, currentTime } = this;
    //   const { subtitles } = this;
    //   currentTime = this.video.currentTime.toFixed(1);
    //   subCount = 0;
    //   while (this.timeCodeMax(subtitles[subCount][1]) < currentTime) {
    //     subCount += 1;
    //     if (subCount > subtitles.length - 1) {
    //       subCount = subtitles.length - 1;
    //       break;
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
.caption-bar {
  position: absolute;
  z-index: 2;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 4px;
  display: block;
  width: 95%;
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  @include bpMedium {
    width: auto;
  }
  @include bpXLarge {
   bottom: 80px;
  }
  &__caption {
    padding: 10px;
    margin: 0;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-shadow:
      -1px -1px 0 #000,
       0   -1px 0 #000,
       1px -1px 0 #000,
       1px  0   0 #000,
       1px  1px 0 #000,
       0    1px 0 #000,
      -1px  1px 0 #000,
      -1px  0   0 #000;
    @media screen and (max-height: 600px) {
      font-size: 14px;
      line-height: 18px;
    }
    br {
      display: none;
    }
    @media screen and (min-width: 650px) {
      br {
        display: block;
      }
      padding: 10px 20px;
      font-size: 22px;
      line-height: 28px;
    }
    text-transform: none;
  }
}
</style>
