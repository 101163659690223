import SceneOneSrt from '@/assets/captions/sceneOne.srt';
import SceneTwoSrt from '@/assets/captions/sceneTwo.srt';
import SceneThreeSrt from '@/assets/captions/sceneThree.srt';
import SceneFourSrt from '@/assets/captions/sceneFour.srt';
import sceneOneImmersiveOne from '@/assets/captions/SceneOneImmersiveOne.srt';
import sceneOneImmersiveTwo from '@/assets/captions/SceneOneImmersiveTwo.srt';
import sceneOneImmersiveThree from '@/assets/captions/SceneOneImmersiveThree.srt';
import sceneTwoImmersiveOne from '@/assets/captions/SceneTwoImmersiveOne.srt';
import sceneTwoImmersiveTwo from '@/assets/captions/SceneTwoImmersiveTwo.srt';
import sceneTwoImmersiveThree from '@/assets/captions/SceneTwoImmersiveThree.srt';
import sceneThreeImmersiveOne from '@/assets/captions/SceneThreeImmersiveOne.srt';
import sceneThreeImmersiveTwo from '@/assets/captions/SceneThreeImmersiveTwo.srt';
import sceneFourImmersiveOne from '@/assets/captions/SceneFourImmersiveOne.srt';
import sceneFourImmersiveTwo from '@/assets/captions/SceneFourImmersiveTwo.srt';
import Agave from '@/assets/svg/agave.svg';
import Truck from '@/assets/svg/truck.svg';
import Toast from '@/assets/svg/toast.svg';
import Distillery from '@/assets/svg/distillery.svg';

export const SCROLL_DIRECTION = {
  DOWN: 'down',
  UP: 'up',
};

export const INITIAL_LOAD_ASSETS = 6;

export const ASSET_BASE_URL = process.env.VUE_APP_ASSET_BASE_URL;

export const CACHE_BUST = Date.now();

export const END_SCREEN_COPY = {
  blanco: 'Don Julio® Blanco Tequila is the base from which all of our other variants are derived. Made from 100% blue weber agave harvested in the fields of La Primavera by the devoted few who carry the legacy&nbsp;forward.',
  seventy: 'Don Julio 70® Añejo Claro Tequila is the culmination of 70 years of knowledge, expertise and innovation that brings the smoothness of a Blanco tequila and the complexity of an Añejo tequila together for a totally unique tequila&nbsp;experience.',
  anejo: 'Barrel aged in smaller batches for eighteen months in American white-oak barrels, Don Julio® Añejo Tequila is a testament to the craft of making a superior tasting, aged&nbsp;tequila.',
  reposado: 'Aged for eight months in American white-oak barrels, Don Julio® Reposado Tequila is golden amber in color, and offers a rich, smooth finish—the very essence of the perfect&nbsp;barrel-aged&nbsp;tequila.',
};

export const COLOR_BY_INDEX = ['red', 'orange', 'blue', 'teal'];
export const HEX_BY_INDEX = ['#C93825', '#DE8826', '#008FDC', '#206C6A'];

export const CANVAS_EVENTS = {
  FULL_SCREEN: 'sceneFullScreen',
  ANIM_IN_COMPLETE: 'sceneAnimInComplete',
  ANIM_OUT_START: 'sceneAnimOutStart',
  ANIM_OUT_COMPLETE: 'sceneAnimOutComplete',
  ANIM_IN_CONTENT: 'sceneAnimInContent',
  ANIM_OUT_CONTENT: 'sceneAnimOutContent',
  MIDWAY_COMPLETE: 'sceneMidwayComplete',
  FULLY_COMPLETE: 'sceneFullyComplete',
  ANIMATE_CANVAS: 'animateCanvas',
  LANDING_ANIM_OUT: 'landingAnimOut',
  UPDATE_TIMELINE_COMPLETION: 'updateTimelineCompletion',
  BUILD_CONTENT_TIMELINE: 'buildContentTimeline',
  CANVAS_READY: 'canvasIsReady',
  FADE_CANVAS: 'fadeCanvas',
  CREATE_MASK: 'create canvas mask',
  SHOW_CONTINUE_PROMPT: 'show continue prompt',
  SHOW_SCROLL_PROMPT: 'show scroll prompt',
  START_END_TRANSITION: 'start out animation',
  ENABLE_SCROLL: 'enable scroll',
  GO_TO_HOTSPOT: 'got to hotspot',
  RESET_TIMELINE: 'reset timeline',
};

export const VIDEO_EVENTS = {
  UPDATE_VIDEO: 'update video',
  PLAY_VIDEO: 'play video',
  PAUSE_VIDEO: 'pause video',
  RENDER_VIDEO: 'render video',
  REMOVE_VIDEO: 'remove video',
  LOAD_VIDEO: 'load video',
  SKIP_VIDEO: 'skip video',
  VIDEO_LOADED: 'video is loaded',
  SEEK_VIDEO: 'set video time',
  SET_OFFSET_VIDEO: 'offset video start to ignore transition',
};

export const APP_EVENTS = {
  INVERT_CURSOR: 'inver cursor',
  SET_ASSET_COUNT: 'set asset count',
  SET_CANVAS_SCROLL: 'set canvas scroll',
  ASSET_LOADED: 'assetLoaded',
  CLEAR_CAPTIONS: 'clear captions',
  SET_ACTIVE_CAPTION: 'set active caption',
  CAPTION_TIME_UPDATE: 'update caption timing',
  ANIMATE_ACTIVE_DOT: 'animate active',
  ANIMATE_VISITED_DOT: 'animate visited',
  HIDE_ACTIVE_TIMELINE: 'hide active timeline',
  RESET_DOT: 'reset dot',
  SHOW_MODAL: 'show app modal',
  HIDE_MODAL: 'hide app modal',
  CHAPTER_CHANGE: 'change current chapter',
  RETURN_TO_START: 'return to start',
  SHOW_CONTROLS: 'show controls',
  READY_LANDING: 'get landing ready',
  LANDING_READY: 'landing reset done',
  START_LANDING: 'start landing anim in',
  MOUSE_ENTER: 'moused over el',
  MOUSE_LEAVE: 'mouse leave el',
  SHOW_FOOTER: 'show app footer',
  ANIMATE_SCROLL: 'animate scroll cursor',
  SHOW_LOADER: 'show Loader',
  ANIM_MESH_IN: 'anim mesh in',
  REMOVE_LOADER: 'remove teaser loader',
  SKIP_INTRO: 'skip intro',
  PLAY_INTRO_MASK: 'introMask',
  PLAY_LANDING_TRANSITION_AUDIO: 'play landing transition audio',
};

export const TEASER_CAPTIONS = [
  {
    lines: ['He started from scratch.', 'He started from nothing.'],
    startTime: 8.791,
    endTime: 13.541,
    position: 'left',
  },
  {
    lines: ['He saw it with his eyes', 'and he would go and', 'feel it with his heart.'],
    startTime: 16.041,
    endTime: 20.125,
    position: 'right',
  },
  {
    lines: ['He was a very visionary,', 'very determined person.'],
    startTime: 21.750,
    endTime: 25.250,
    position: 'left',
  },
  {
    lines: ['Don Julio valued people', 'over anything else.'],
    startTime: 28.083,
    endTime: 31.458,
    position: 'right',
  },
];

export const MODAL_TYPES = {
  CAROUSEL: 'ModalCarousel',
  END_SCENE: 'EndScene',
};

export const VIDEO_CONFIGS = [
  {
    video: `${ASSET_BASE_URL}scenes/sceneOne.mp4`,
    sceneIcon: Agave,
    caption: SceneOneSrt,
  },
  {
    video: `${ASSET_BASE_URL}scenes/sceneTwo.mp4`,
    sceneIcon: Distillery,
    caption: SceneTwoSrt,
  },
  {
    video: `${ASSET_BASE_URL}scenes/sceneThree.mp4`,
    sceneIcon: Truck,
    caption: SceneThreeSrt,
  },
  {
    video: `${ASSET_BASE_URL}scenes/sceneFour.mp4`,
    sceneIcon: Toast,
    caption: SceneFourSrt,
  },
];

export const SCENE_HOTSPOT_POSITIONS = [
  [4.1, 8.6, 12.3],
  [4.7, 8.5, 12.3],
  [3.5, 9.5],
  [3.4, 8.1],
];

export const SCENE_CONTENT_POSITIONS = [
  [
    {
      large: { top: '41%', left: '71%' },
      medium: { top: '41%', left: '76%' },
      small: { top: '50%', left: '58%' },
    },
    {
      large: { top: '50%', left: '50%' },
      medium: { top: '50%', left: '55%' },
      small: { top: '50%', left: '55%' },
    },
    {
      large: { top: '50%', left: '32%', maxWidth: '470px' },
      medium: { top: '50%', left: '42%' },
      small: { top: '50%', left: '55%', width: '72%' },
    },
  ],
  [
    {
      large: { top: '41%', left: '60%' },
      medium: { top: '41%', left: '60%' },
      small: { top: '50%', left: '60%', width: '69%' },
    },
    {
      large: { top: '50%', left: '41%' },
      medium: { top: '50%', left: '41%' },
      small: { top: '50%', left: '54%' },
    },
    {
      large: { top: '50%', left: '25%' },
      medium: { top: '40%', left: '43%', maxWidth: '325px' },
      small: { top: '40%', left: '54%' },
    },
  ],
  [
    {
      large: { top: '50%', left: '30%', maxWidth: '400px' },
      medium: { top: '36%', left: '36%' },
      small: { top: '50%', left: '54%', maxWidth: '301px' },
    },
    {
      large: { top: '63%', left: '46%' },
      medium: { top: '35%', left: '50%' },
      small: { top: '39%', left: '57%', width: '76%' },
    },
  ],
  [
    {
      large: { top: '41%', left: '72%', maxWidth: '400px' },
      medium: { top: '50%', left: '70%', maxWidth: '300px' },
      small: { top: '47%', left: '60%', maxWidth: '301px' },
    },
    {
      large: { top: '48%', left: '69%', maxWidth: '490px' },
      medium: { top: '48%', left: '68%' },
      small: { top: '45%', left: '60%', maxWidth: '290px' },
    },
  ],
];

export const SCENE_CONTENT_TIMING = [
  [
    [{ time: 1.6, type: 'out' }, { time: 2.2, type: 'in' }, { time: 5.4, type: 'check' }],
    [{ time: 6.4, type: 'out' }, { time: 7.0, type: 'in' }, { time: 9.1, type: 'check' }],
    [{ time: 11, type: 'out' }, { time: 11.6, type: 'in' }, { time: 14, type: 'check' }],
  ],
  [
    [{ time: 0.6, type: 'out' }, { time: 1.2, type: 'in' }, { time: 5.1, type: 'check' }],
    [{ time: 5.4, type: 'out' }, { time: 6, type: 'in' }, { time: 9.1, type: 'check' }],
    [{ time: 10.5, type: 'out' }, { time: 10.7, type: 'in' }, { time: 13, type: 'check' }],
  ],
  [
    [{ time: 0.6, type: 'out' }, { time: 1.2, type: 'in' }, { time: 5.1, type: 'check' }],
    [{ time: 6.4, type: 'out' }, { time: 6.7, type: 'in' }, { time: 9.9, type: 'check' }],
  ],
  [
    [{ time: 1.9, type: 'out' }, { time: 2.3, type: 'in' }, { time: 5.1, type: 'check' }],
    [{ time: 6.7, type: 'out' }, { time: 6.8, type: 'in' }, { time: 9.8, type: 'check' }],
  ],
];

export const SCENE_IMMERSIVE_SLIDE_SHOW = [
  [
    [
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselOne1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselOne2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselOne3.jpg`,
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselOne4.jpg`,
    ],
    [
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselTwo1.jpg`,
    ],
    [
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselThree1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselThree2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneOne/sceneOneCarouselThree3.jpg`,
    ],
  ],
  [
    [],
    [
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselTwo1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselTwo2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselTwo3.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselTwo4.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselTwo5.jpg`,
    ],
    [
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselThree1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselThree2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselThree3.jpg`,
      `${ASSET_BASE_URL}scenes/sceneTwo/sceneTwoCarouselThree4.jpg`,
    ],
  ],
  [
    [
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselOne1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselOne2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselOne3.jpg`,
    ],
    [
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselTwo1.jpg`,
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselTwo2.jpg`,
      `${ASSET_BASE_URL}scenes/sceneThree/sceneThreeCarouselTwo3.jpg`,
    ],
  ],
];

export const SCENE_IMMERSIVE_VOICE_OVER = [
  [
    {
      audio: `${ASSET_BASE_URL}audio/sceneOneImmersiveOne.mp3`,
      caption: sceneOneImmersiveOne,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneOneImmersiveTwo.mp3`,
      caption: sceneOneImmersiveTwo,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneOneImmersiveThree.mp3`,
      caption: sceneOneImmersiveThree,
    },
  ],
  [
    {
      audio: `${ASSET_BASE_URL}audio/sceneTwoImmersiveOne.mp3`,
      caption: sceneTwoImmersiveOne,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneTwoImmersiveTwo.mp3`,
      caption: sceneTwoImmersiveTwo,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneTwoImmersiveThree.mp3`,
      caption: sceneTwoImmersiveThree,
    },
  ],
  [
    {
      audio: `${ASSET_BASE_URL}audio/sceneThreeImmersiveOne.mp3`,
      caption: sceneThreeImmersiveOne,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneThreeImmersiveTwo.mp3`,
      caption: sceneThreeImmersiveTwo,
    },
  ],
  [
    {
      audio: `${ASSET_BASE_URL}audio/sceneFourImmersiveOne.mp3`,
      caption: sceneFourImmersiveOne,
    },
    {
      audio: `${ASSET_BASE_URL}audio/sceneFourImmersiveTwo.mp3`,
      caption: sceneFourImmersiveTwo,
    },
  ],
];

export const coverImg = (ctx, img, type = 'cover') => {
  const imgRatio = img.videoHeight / img.videoWidth;
  const winRatio = window.innerHeight / window.innerWidth;
  if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
    const h = window.innerWidth * imgRatio;
    ctx.drawImage(img, 0, (window.innerHeight - h) / 2, window.innerWidth, h);
  }
  if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
    const w = (window.innerWidth * winRatio) / imgRatio;
    ctx.drawImage(img, (window.innerWidth - w) / 2, 0, w, window.innerHeight);
  }
};
