<template>
  <div class="loader">
    <img :src="background" alt="" class="loader__background" @load="handleImageLoad">
    <img :src="fallbackImage" alt="" class="loader__fallback">
    <div class="loader__content">
      <div class="loader__content__title">
        <span
          class="loader__content__title__border loader__content__title__border--top"
          v-html="CaptionBorder"
          ref="topBorder"
        />
        <FadeQuote :copy="content.loader_headline" ref="title" />
        <span
          class="loader__content__title__border loader__content__title__border--bottom"
          v-html="CaptionBorder"
          ref="topBorder"
        />
      </div>
      <div class="loader__content__buttons">
        <div class="loader__content__loader">
          <span class="box" ref="loaderBox" />
        </div>
        <div class="audio-buttons">
          <button
            class="audio-buttons__button audio-buttons__button--on"
            ref="audioOn"
            @click="handleAudio(false)"
            aria-label="proceed with audio"
          >
            <span class="box" />
            <span v-html="LoaderAudioOn" />
            <p v-html="content.audio_on" />
          </button>
          <button
            class="audio-buttons__button audio-buttons__button--off"
            @click="handleAudio(true)"
            ref="audioOff"
            aria-label="proceed without audio"
          >
            <span class="box" />
            <span v-html="LoaderAudioOff" />
            <p v-html="content.audio_off" />
          </button>
        </div>
      </div>
      <p class="loader-copy">LOADING EXPERIENCE</p>
    </div>
    <transition name="fade">
      <TeaserCaptions :time="currentTime" v-if="!introSkipped" />
    </transition>
    <transition name="fade">
      <div
        class="skip-button"
        @click="handleSkipIntro"
        @keydown.enter="handleSkipIntro"
        tabindex="0"
        v-if="showSkip && !introSkipped"
      >
        <VideoButton :icon="skipIcon" color="red" />
        <p>Skip Intro</p>
      </div>
    </transition>
  </div>
</template>

<script>
import { inject } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { gsap } from 'gsap/gsap-core';
import { ASSET_BASE_URL, APP_EVENTS } from '@/core/constants';
import CaptionBorder from '@/assets/svg/captionBorder.svg';
import LoaderAudioOn from '@/assets/svg/loaderAudioOn.svg';
import LoaderAudioOff from '@/assets/svg/loaderAudioOff.svg';
import Skip from '@/assets/svg/skipIcon.svg';
import FadeQuote from './FadeQuote.vue';
import eventBus from '../core/eventBus';
import TeaserCaptions from './TeaserCaptions.vue';
import VideoButton from './VideoButton.vue';
import videoTracking from '../core/videoTracking';

export default {
  name: 'LoaderView',
  mixins: [videoTracking],
  components: {
    FadeQuote,
    TeaserCaptions,
    VideoButton,
  },
  props: {
    loadValue: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    CaptionBorder,
    LoaderAudioOn,
    LoaderAudioOff,
    displayValue: 0,
    tweenValue: 0,
    backgroundLoaded: false,
    loadComplete: false,
    animComplete: false,
    animStarted: false,
    debug: true,
    videoStartTime: 0,
    animationId: null,
    teaserStarted: false,
    introSkipped: false,
    showSkip: false,
    animateCalled: false,
    borderHeight: 0,
    videoTrackingId: 'e-intro-video-view',
    videoTrackingLabel: 'Intro Video',
    fallbackImage: `${ASSET_BASE_URL}images/loader-fallback.jpg`,
  }),
  setup() { return { tracking: inject('tracking') }; },
  computed: {
    background() {
      return this.isMobile
        ? `${ASSET_BASE_URL}images/intro_bg-small.jpg`
        : `${ASSET_BASE_URL}images/intro_bg.jpg`;
    },
    content() {
      return this.getContentByPath('app.global');
    },
    skipIcon: () => Skip,
    contentStyle() {
      return { marginTop: `${this.borderHeight / 2}px` };
    },
    ...mapGetters(['getContentByPath', 'isMobile']),
    ...mapState(['viewWidth', 'viewHeight']),
  },
  watch: {
    viewWidth() {
      this.updateMargin();
    },
    viewHeight() {
      this.updateMargin();
    },
    loadValue(val) {
      if (!this.animStarted) return;
      const loadVal = (val / 100) * 0.8;
      gsap.to(this.$refs.loaderBox, {
        delay: 1,
        scale: loadVal < 0.8 ? loadVal : 0.8,
        duration: 2,
        onComplete: () => {
          if (val >= 100) {
            this.loadComplete = true;
          }
        },
      });
    },
    loadComplete() {
      gsap.to(['.loader__content__loader', '.loader-copy'], {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.5,
        overwrite: true,
        ease: 'power2.out',
        onComplete: () => {
          gsap.to('.loader__content__title__border', {
            opacity: 1,
            duration: 0.7,
            delay: 0.3,
            ease: 'power2.out',
          });
          gsap.delayedCall(0.2, this.$refs.title.animIn);
          gsap.to([this.$refs.audioOn, this.$refs.audioOff], {
            opacity: 1,
            pointerEvents: 'initial',
            duration: 0.5,
            delay: 0.3,
            ease: 'power2.out',
          });
        },
      });
      eventBus.emit(APP_EVENTS.PLAY_INTRO_MASK);
    },
  },
  mounted() {
    eventBus.on('canvasReady', () => { gsap.to('.loader__background', { opacity: 0, delay: 1.5, overwrite: true }); });
    eventBus.on(APP_EVENTS.START_LANDING, () => { this.showSkip = false; });
    eventBus.on('introMaskFail', this.handleFallback);
    this.$nextTick(() => { this.updateMargin(); });
  },
  methods: {
    ...mapActions(['setAudioMuted']),
    playAnimation() {
      if (this.animateCalled) return;
      this.animateCalled = true;
      // const tl = gsap.timeline();
      this.tracking.trackEvent('p-loader');
      gsap.to(['.loader-copy', '.loader__content__loader'], {
        opacity: 1,
        duration: 0.7,
        ease: 'power2.out',
        onStart: () => {
          if (this.loadValue >= 100) {
            gsap.to(this.$refs.loaderBox, {
              scale: 0.8,
              delay: 1,
              duration: 2,
              onComplete: () => { this.loadComplete = true; },
            });
          } else this.animStarted = true;
        },
      });
    },
    handleImageLoad() {
      if (this.loadValue < 100) {
        gsap.to('.loader__background', {
          opacity: 1,
          ease: 'power2.out',
          duration: 0.5,
          delay: 0.75,
          onComplete: () => { this.$emit('backgroundLoaded'); },
        });
      }
    },
    updateMargin() {
      this.borderHeight = this.$refs.topBorder.clientHeight;
    },
    handleFallback() {
      gsap.to('.loader__fallback', {
        opacity: 1,
        duration: 0.8,
        delay: 0.3,
        ease: 'power1.out',
      });
    },
    handleAudio(muted) {
      this.tracking.trackEvent('p-hero-video');
      this.tracking.trackEvent(!muted ? 'e-audio-1' : 'e-audio-2');
      this.videoDuration = document.getElementById('teaserIntroVideo').duration;
      this.setAudioMuted(muted);
      this.teaserStarted = true;
      gsap.set([this.$refs.audioOn, this.$refs.audioOff], { pointerEvents: 'none' });
      gsap.to(['.loader__content', '.loader__fallback'], {
        opacity: 0,
        pointerEvents: 'none',
        duration: 0.5,
        ease: 'power2.out',
        onComplete: () => { this.showSkip = true; },
      });
      eventBus.emit(APP_EVENTS.ANIM_MESH_IN);
      eventBus.emit(APP_EVENTS.PLAY_LANDING_TRANSITION_AUDIO, 1.4);
      this.videoStartTime = Date.now();
      this.updateVideoProgress();
    },
    handleSkipIntro() {
      cancelAnimationFrame(this.animationId);
      this.tracking.trackEvent('e-intro-video-skip');
      this.introSkipped = true;
      eventBus.emit(APP_EVENTS.SKIP_INTRO);
    },
    updateVideoProgress() {
      this.currentTime = (Date.now() - this.videoStartTime) / 1000;
      this.checkTrackVideo();
      this.animationId = requestAnimationFrame(this.updateVideoProgress);
    },
  },
};
</script>

<style lang="scss">
.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  transform: translateZ(0);
  .skip-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &:focus { outline: none; }
    @include on-hover {
      .video-button {
        &::before {
          transform: scale(1);
          opacity: 1;
        }
        &::after {
          transform: scale(1.2);
          opacity: 0;
        }
      }
    }
    .video-button {
      width: 30px;
      height: 30px;
      margin: 0;
      svg {
        width: 70%;
      }
    }
    p {
      margin: 0;
      color: white;
      margin-left: 10px;
    }
  }
  &.disabled { pointer-events: none; }
  .logo {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 4;
  }
  &__background, &__fallback {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
  }
  &__content {
    z-index: 3;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 90%;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    .loader-copy {
      margin-top: 27px;
      margin-bottom: 0;
      opacity: 0;
    }
    .subhead {
      margin-bottom: 50px;
      text-transform: none;
      font-size: 16px;
      text-align: center;
      opacity: 0;
    }
    &__buttons {
    }
    .audio-buttons {
      position: absolute;
      bottom: 18%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      &__button {
        margin: 0 50px;
        position: relative;
        background: none;
        width: 45px;
        height: 45px;
        transform: rotate(45deg);
        border: none;
        cursor: pointer;
        transform-origin: center center;
        opacity: 0;
        pointer-events: none;
        -webkit-tap-highlight-color: transparent;
        p {
          position: absolute;
          width: 120px;
          bottom: 0;
          left: 50%;
          right: 0;
          top: 0;
          text-align: center;
          transform: rotate(-45deg) translate(-34%, 0);
          transform-origin: center;
          margin: 0;
          white-space: nowrap;
          padding-top: 30px;
         }
        .box {
          content: '';
          @include centerAbsolute;
          background-position: center;
          background-blend-mode: multiply;
          background-image: url('../assets/images/teal-texture.png');
          transform: translate(-50%, -50%) scale(0.8);
          transition: transform 300ms $easeOutMaterial;
          z-index: 1;
        }
        &--off {
          transform: rotate(45deg);
          .box {
            background-image: url('../assets/images/red-texture.png');
          }
        }
        &::after, &::before {
          content: '';
          @include centerAbsolute;
          border: 1px solid rgba(0, 0, 0, 0.1);
          width: 65px;
          height: 65px;
        }
        &::before {
          border: 1px solid rgba(0, 0, 0, 0.3);
          width: 45px;
          z-index: 2;
          height: 45px;
          transition: transform 300ms $easeOutMaterial, border-color 300ms $easeOutMaterial;
        }
        svg {
          transform: rotate(-45deg) translateX(-1px);
          position: relative;
          z-index: 2;
        }
        @include on-hover {
          .box { transform: translate(-50%, -50%) scale(1); }
          &::before {
            transform: translate(-50%, -50%) scale(0.8);
            border-color: white;
          }
        }
      }
    }
    &__title {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      width: 90%;
      transform: translate(-50%, -100%);
      white-space: normal;
      @include bpMedium {
        width: auto;
      }
      &__border {
        position: absolute;
        width: 30%;
        max-width: 100px;
        stroke: white;
        opacity: 0;
        top: 0;
        left: 0;
        transform: translate(-30%, -50%);
        &--bottom {
          top: auto;
          left: auto;
          bottom: 0;
          right: 0;
          transform: rotate(180deg) translate(-30%, -50%);
        }
      }
      .fade-quote {
        color: white;
        &__title {
          color: white;
          font-size: MIN(88px, MAX(52px, 10vw));
          line-height: 0.9;
          white-space: normal;
          margin-bottom: 0;
          br {
            display: none;
          }
          @include bpMedium {
            white-space: nowrap;
            br {
              display: block;
            }
          }
          @media screen and (max-height: 800px) and (min-width: 1024px) {
            font-size: MIN(108px, MAX(90px, 12vh));
          }
        }
      }
    }
    &__loader {
      position: relative;
      width: 45px;
      height: 45px;
      outline: 1px solid rgba(0, 0, 0, 0.3);
      transform: rotate(45deg);
      transform-origin: center;
      opacity: 0;
      &::before {
        content: '';
        width: 65px;
        height: 65px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        outline: 1px solid rgba(0, 0, 0, 0.1);
      }
      .box {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        background-blend-mode: multiply;
        background-position: center;
        background-image: url('../assets/images/teal-texture.png');
        transform: translate(-50%, -50%) scale(0);
        transform-origin: center center;
      }
    }
  }
}
</style>
