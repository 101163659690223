/* eslint radix: ["error", "as-needed"] */
import { createStore } from 'vuex';
import { UAParser } from 'ua-parser-js';
import video from './modules/video';
import canvas from './modules/canvas';
import * as types from './mutationTypes';
import { SCROLL_DIRECTION } from '../core/constants';

const parsedAgent = UAParser(window.navigator.userAgent);

/**
 * True if the device uses iOS
 */
const IS_IOS = parsedAgent.os.name === 'iOS';
const IS_FF = parsedAgent.browser.name === 'Firefox';
const IS_MACOS_SAFARI = parsedAgent.browser.name === 'Safari' && parsedAgent.os.name === 'Mac OS';
const NEEDS_IOS_FALLBACK = IS_IOS && parseFloat(parsedAgent.os.version) > 15.5;
const IS_IPADOS = parsedAgent.browser.name === 'Safari' && navigator.maxTouchPoints && navigator.maxTouchPoints > 2;

export default createStore({
  state: {
    debug: !!parseInt(process.env.VUE_APP_SHOW_VIDEO_DEBUG),
    viewWidth: 0,
    viewHeight: 0,
    activeSceneIndex: 0,
    activeHotspotDot: 0,
    activeRecipes: null,
    skipClick: false,
    activeBottleName: '',
    uiSceneIndex: 0,
    mousePositionX: 0,
    mousePositionY: 0,
    showLoader: false,
    scrollDirection: SCROLL_DIRECTION.DOWN,
    audioMuted: true,
    captionsOn: true,
    userInteracted: false,
    appStarted: false,
    appLoaded: false,
    modalOpen: false,
    endScene: false,
    resetApp: false,
    sceneTransitioning: false,
    app: null,
    copyLoaded: false,
    roadblockVisible: false,
  },
  getters: {
    mousePosition: (state) => ({ x: state.mousePositionX, y: state.mousePositionY }),
    displayIndex: (state) => state.activeSceneIndex + 1,
    isTouchDevice: () => {
      if (window.matchMedia('(pointer: coarse)').matches || ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)) {
        return true;
      }
      return false;
    },
    isIOS: () => IS_IOS,
    isFF: () => IS_FF,
    isMacOsSafari: () => IS_MACOS_SAFARI,
    isIpadOs: () => IS_IPADOS,
    isMobile: (state) => state.viewWidth < 600,
    needsIosFallback: () => NEEDS_IOS_FALLBACK,
    getContentByPath: (state) => (path) => {
      try {
        const pathArray = path.split('.');
        let obj = state.app;
        for (let i = 0; i < pathArray.length; i += 1) {
          obj = obj[pathArray[i]];
        }
        return obj;
      } catch (error) {
        return `string ${path} not found in state`;
      }
    },
  },
  mutations: {
    [types.SET_SHOW_DEBUG](state, val) {
      state.debug = val;
    },
    [types.SET_APP_COPY](state, app) {
      state.app = app;
      state.copyLoaded = true;
    },
    [types.SET_SCROLL_DIRECTION](state, direction) {
      state.scrollDirection = direction;
    },
    [types.SET_SHOW_END_SCENE](state, val) {
      state.endScene = val;
    },
    [types.SET_AUDIO_MUTED](state, bool) {
      state.videoPlaying = bool;
    },
    [types.SET_WINDOW_SIZE](state, { dimensions }) {
      state.viewWidth = dimensions.width;
      state.viewHeight = dimensions.height;
    },
    [types.SET_MOUSE_POSITION](state, { mousePosition }) {
      state.mousePositionX = mousePosition.x;
      state.mousePositionY = mousePosition.y;
    },
    [types.SET_AUDIO_MUTED](state, bool) {
      state.audioMuted = bool;
    },
    [types.SET_CAPTIONS_ON](state, bool) {
      state.captionsOn = bool;
    },
    [types.SET_APP_STARTED](state) {
      state.appStarted = true;
    },
    [types.SET_APP_LOADED](state) {
      state.appLoaded = true;
    },
    [types.SET_ACTIVE_SCENE_INDEX](state, index) {
      state.activeSceneIndex = index;
    },
    [types.SET_UI_SCENE_INDEX](state, index) {
      state.uiSceneIndex = index;
    },
    [types.SET_USER_INTERACTED](state) {
      state.userInteracted = true;
    },
    [types.SET_SHOW_ROADBLOCK](state, bool) {
      state.roadblockVisible = bool;
    },
    [types.SET_MODAL_OPEN](state, bool) {
      state.modalOpen = bool;
    },
    [types.SET_RESET_APP](state, bool) {
      state.resetApp = bool;
    },
    [types.SET_SCENE_TRANSITIONING](state, bool) {
      state.sceneTransitioning = bool;
    },
    [types.SET_ACTIVE_RECIPES](state, data) {
      state.activeRecipes = data;
    },
    [types.SET_ACTIVE_HOTSPOT_DOT](state, idx) {
      state.activeHotspotDot = idx;
    },
    [types.SET_SKIP_CLICK](state, bool) {
      state.skipClick = bool;
    },
  },
  actions: {
    setAudioMuted({ commit }, bool) {
      commit(types.SET_AUDIO_MUTED, bool);
    },
    setSkipClick({ commit }, bool) {
      commit(types.SET_SKIP_CLICK, bool);
    },
    setActiveHotspotDot({ commit }, idx) {
      commit(types.SET_ACTIVE_HOTSPOT_DOT, idx);
    },
    setCaptionsOn({ commit }, bool) {
      commit(types.SET_CAPTIONS_ON, bool);
    },
    setAppStarted({ commit }) {
      commit(types.SET_APP_STARTED);
    },
    setAppLoaded({ commit }) {
      commit(types.SET_APP_LOADED);
    },
    setActiveSceneIndex({ commit }, index) {
      commit(types.SET_ACTIVE_SCENE_INDEX, index);
    },
    setUISceneIndex({ commit }, index) {
      commit(types.SET_UI_SCENE_INDEX, index);
    },
    setSceneTransitioning({ commit }, bool) {
      commit(types.SET_SCENE_TRANSITIONING, bool);
    },
    setShowEndScene({ commit }, val) {
      commit(types.SET_SHOW_END_SCENE, val);
    },
    setUserInteracted({ commit }) {
      commit(types.SET_USER_INTERACTED);
    },
    setModalOpen({ commit }, bool) {
      commit(types.SET_MODAL_OPEN, bool);
    },
    setResetApp({ commit }, bool) {
      commit(types.SET_RESET_APP, bool);
    },
    setActiveRecipes({ commit }, data) {
      commit(types.SET_ACTIVE_RECIPES, data);
    },
  },
  modules: {
    video,
    canvas,
  },
});
