export const SET_VIDEO_INDEX = 'video/Set current video index';
export const SET_VIDEO_RENDER = 'video/Set whether to render the video';
export const SET_VIDEO_READY = 'video/Set the current video to a ready state';
export const SET_VIDEO_PLAYING = 'video/Set the videp o playing';
export const SET_VIDEO_COMPLETE_PERCENT = 'video/Set video completion percent';
export const SET_VIDEO_ACTIVE = 'video/Set Video active';
export const SET_RESET_VIDEO = 'video/Set reset video';

export const SET_CANVAS_INDEX = 'canvas/Set current canvas index';
export const SET_CANVAS_RENDER = 'canvas/Set whether to render the canvas';
export const SET_CANVAS_READY = 'canvas/Set the current canvas to a ready state';
export const SET_CANVAS_ACTIVE = 'canvas/Set the current canvas to capture mouse events';
export const SET_CANVAS_IGNORE_OUT = 'canvas/Set ignore the fade out event';

export const SET_APP_COPY = 'Set app copy';
export const SET_WINDOW_SIZE = 'Set window size';
export const SET_MOUSE_POSITION = 'Set mouse position';
export const SET_SCROLL_DIRECTION = 'Set scroll direction';
export const SET_USER_INTERACTED = 'Set user interacted';
export const SET_SHOW_DEBUG = 'Set show video controls';
export const SET_AUDIO_MUTED = 'Set audio muted';
export const SET_CAPTIONS_ON = 'Set captions on';
export const SET_APP_LOADED = 'Set app loaded';
export const SET_APP_STARTED = 'Set app started';
export const SET_ACTIVE_SCENE_INDEX = 'Set active scene index';
export const SET_UI_SCENE_INDEX = 'set scene index for UI';
export const SET_SHOW_END_SCENE = 'set show end scene';
export const SET_SHOW_ROADBLOCK = 'show landscape roadblock';
export const SET_MODAL_OPEN = 'set modal open';
export const SET_RESET_APP = 'set reset app';
export const SET_SCENE_TRANSITIONING = 'set scene transitioning';
export const SET_ACTIVE_RECIPES = 'set active recipes';
export const SET_SKIP_CLICK = 'set skip click';
export const SET_ACTIVE_HOTSPOT_DOT = 'set active hotspot dot';
