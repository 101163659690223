<template>
  <div class="fade-quote">
    <h1 class="fade-quote__title" ref="quote" v-if="!small">
      <span v-html="computedCopy" />
    </h1>
    <h3 class="fade-quote__title" ref="quote" v-else>
      <span v-html="computedCopy" />
    </h3>
  </div>
</template>

<script>
import { gsap } from 'gsap';
import CSSPlugin from 'gsap/CSSPlugin';
import { SplitText } from 'gsap/SplitText';

gsap.registerPlugin(SplitText);
gsap.registerPlugin(CSSPlugin);

export default {
  name: 'fade-quote',
  props: {
    copy: {
      type: String,
      required: true,
    },
    hasQuotes: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: Boolean,
      default: false,
    },
    fadeOut: {
      type: Boolean,
      default: false,
    },
    buildImmediate: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    quoteTL: null,
    results: null,
  }),
  computed: {
    computedCopy() {
      return this.hasQuotes ? `“&NoBreak;${this.copy}&NoBreak;”` : this.copy;
    },
  },
  watch: {
    animate(val) {
      if (val) {
        this.animIn();
      }
    },
  },
  mounted() {
    if (this.buildImmediate || this.$route.name === 'Sandbox') {
      this.$nextTick(() => {
        this.buildAnim();
      });
    }
  },
  methods: {
    buildAnim() {
      this.results = new SplitText(this.$refs.quote, {
        wordsClass: 'word',
        charsClass: 'char',
        type: 'words, chars',
      });
      this.shuffle(this.results.chars);
      this.quoteTL = gsap.timeline({
        paused: true,
        onComplete: () => { this.$emit('animComplete'); },
      });
      gsap.set(this.results.chars, { opacity: 0 });
      if (this.fadeOut) {
        gsap.to(this.results.chars, {
          opacity: 0,
          delay: 2.9,
          duration: 1,
        });
      }
    },
    animIn() {
      gsap.set(this.results.chars, { opacity: 0 });
      gsap.to(this.results.chars, {
        opacity: 1,
        ease: 'power2.out',
        duration: 1,
        stagger: 0.005,
      });
    },
    fadeOutText() {
      gsap.to(this.results.chars, {
        opacity: 0,
        ease: 'power2.out',
        duration: 1,
      });
    },
    shuffle(arr) {
      const array = arr;
      let currentIndex = array.length;
      let randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
      }
      return array;
    },
  },
};
</script>

<style lang="scss">
.fade-quote {
  max-width: 100%;
  &__title {
    position: relative;
    margin-bottom: 20px;
    text-align: center;
    text-align: center;
    color: white;
    font-family: $headlineFont;
    .char {
      font-family: $headlineFont;
      margin: 0 0.25px;
    }
    .word {
      font-family: $headlineFont;
      margin: 0 1px;
      @include bpMedium {
        margin: 0 2px;
      }
      &:first-of-type { margin-left: 0; }
      &:last-of-type { margin-right: 0; }
    }
  }
};
</style>
