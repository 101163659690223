<template>
 <div class="logo" :class="color">
    <span
      class="logo__logo"
      v-if="!goldBlack"
      v-html="!goldBlack ? LogoSvg : LogoBlackGold"
    />
  </div>
</template>

<script>
import LogoSvg from '@/assets/svg/logo.svg';
import LogoBlackGold from '@/assets/svg/logoBlackGold.svg';

export default {
  name: 'DJLogo',
  data: () => ({
    LogoSvg,
    LogoBlackGold,
  }),
  props: {
    color: {
      type: String,
      default: 'black',
    },
    goldBlack: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.logo {
  display: flex;
  width: 100%;
  pointer-events: auto;
  padding: 20px;
  box-sizing: border-box;
  @include bpMedium {
    justify-content: center;
    align-items: center;
  }
  &__logo {
    transition: fill 300ms $easeOutMaterial;
    width: 75px;
    height: 51px;
    svg {
      fill: $black;
    }
  }
  &.white {
    .logo {
      &__logo, &__accent {
        svg {
          fill: white;
        }
      }
    }
  }
}
</style>
