import { EVENTS, TAGS_TO_CLEAR } from './events';

export default class Tracking {
  constructor() {
    this.events = EVENTS;
    this.tagsToClear = {};
    window.dataLayer = window.dataLayer || [];
    TAGS_TO_CLEAR.forEach((val) => { this.tagsToClear[val] = undefined; });
  }

  trackEvent = (id, vars = {}) => {
    const event = this.events[id];
    if (parseFloat(process.env.VUE_APP_DEBUG_TRACKING)) {
      console.log('Tracking::', { ...event, ...vars });
      return;
    }
    window.dataLayer.push({ ...this.tagsToClear });
    window.dataLayer.push({ ...event, ...vars });
  }
}
