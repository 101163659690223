<template>
  <button
    class="pulse-button hoverable"
    :class="[color, { icon: iconOnly, disabled, active }]"
    @click="$emit('click')"
    :aria-label="copy ? copy : 'Button Icon'"
  >
    <div class="pulse-button__background" :class="[hoverColor]" />
    <span class="pulse-button__copy">{{copy}}</span>
    <span v-html="icon" class="pulse-button__icon" v-if="icon" :class="[type]" />
  </button>
</template>

<script>

export default {
  name: 'PulseButton',
  props: {
    icon: {
      default: null,
    },
    iconOnly: {
      default: false,
    },
    active: {
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    hoverColor: {
      type: String,
      default: '',
    },
    type: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    copy: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
$duration: 1.5s;
.pulse-button {
  height: 48px;
  min-width: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border: none;
  position: relative;
  background: none;
  color: white;
  text-transform: uppercase;
  line-height: 1px;
  overflow: visible;
  backface-visibility: hidden;
  transition: color 250ms $easeOutMaterial;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &__copy {
    color: white;
    margin: -1px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
  }
  &.icon {
    min-width: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    .pulse-button__icon {
      margin: 0;
    }
    .pulse-button__background {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }
  &.active {
    .pulse-button__background {
      opacity: 1;
    }
  }
  @include on-hover {
    &:not(.pulse-button.icon) {
      .pulse-button__icon { transform: translateX(5px); }
    }
    color: white;
    .pulse-button {
      &__background {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
    &::before {
      width: calc(100% - 6px);
      height: calc(100% - 6px);
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
    &::after {
      width: calc(100% - 1px);
      height: calc(100% - 1px);
      border: 1px solid $orange;
    }
    &.black {
      &::after { border: 1px solid $black; }
      .pulse-button__icon {
        stroke: white;
      }
    }
    &.blue { &::after { border-color: $blue; } }
    &.red { &::after { border-color: $red; } }
    &.orange { &::after { border-color: $orange; } }
    &.teal { &::after { border-color: $teal; } }
    &.landing-red {
      .pulse-button {
        &__background {
          background: $red;
        }
        &__icon {
          stroke: white;
        }
      }
      &::after { border: 1px solid $red; }
    }
    &.black-inverted {
      &::after { border: 1px solid $black; }
      &::before { border: 1px solid rgba(255, 255, 255, 0.8); }
    }
  }
  &.black-inverted {
    .pulse-button__background {
      opacity: 1;
      background: black;
    }
    &::after {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  &.black {
    .pulse-button__background {
      background: black;
    }
    &::before {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }
    &::after {
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  &.landing-red {
    color: $black;
    .pulse-button {
      &__icon {
        stroke: $black;
      }
      &__background {
        background: rgba(201, 56, 37, 0.4);
        opacity: 1;
      }
    }
    &::before {
      border: 1px solid rgba($red, 1);
    }
    &::after {
      border: 1px solid rgba($red, 1);
    }
  }
  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.98, 0.88);
    background-blend-mode: multiply;
    opacity: 0;
    transition: transform 250ms $easeOutMaterial,
                opacity 250ms $easeOutMaterial,
                background 250ms $easeOutMaterial;
    pointer-events: none;
    background-position: center;
    &.red { background-image: url('../assets/images/red-texture.png'); }
    &.blue {  background-image: url('../assets/images/blue-texture.png'); }
    &.teal { background-image: url('../assets/images/teal-texture.png'); }
    &.orange { background-image: url('../assets/images/orange-texture.png'); }
  }
  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 2;
    pointer-events: none;
    will-change: height, width;
    transition: width 250ms $easeOutMaterial,
                height 250ms $easeOutMaterial,
                border 250ms $easeOutMaterial;
  }
  &::after {
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    border: 1px solid rgba(255, 255, 255, 0.4);
  }
  &__icon {
    margin-left: 10px;
    transition: transform 250ms $easeOutMaterial,
                stroke 250ms $easeOutMaterial;
                stroke: white;
    svg {
      transition: stroke 250ms $easeOutMaterial;
    }
    width: 8px;
    &.pause {
      width: 5px;
      right: 25px;
    }
    &.slant {
      width: 10px;
      top: 51%;
      opacity: 1;
    }
  }
  &.black {
    color: black;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
    .pulse-button {
      &__icon {
        opacity: 1;
        stroke: black;
      }
      &__background {
        background: black;
      }
    }
  }
}
</style>
