import { VIDEO_CONFIGS } from '@/core/constants';
import getTypesForScope from '../utils';

const types = getTypesForScope('video');

const moduleState = {
  currentVideoIndex: null,
  videoReady: false,
  videoPlaying: false,
  renderVideo: false,
  videoActive: false,
  videoMuted: true,
  videoCompletePercent: 0,
  resetVideo: false,
};

const getters = {
  currentVideo: (state, moduleGetters, rootState) => {
    const { app } = rootState.app;
    if (state.currentVideoIndex === 'null') return {};
    return {
      ...VIDEO_CONFIGS[state.currentVideoIndex],
      ...app.scenes[state.currentVideoIndex],
    };
  },
};

const mutations = {
  [types.SET_VIDEO_INDEX](state, val) {
    state.currentVideoIndex = val;
  },
  [types.SET_RESET_VIDEO](state, val) {
    state.resetVideo = val;
  },
  [types.SET_VIDEO_RENDER](state, bool) {
    state.renderVideo = bool;
  },
  [types.SET_VIDEO_ACTIVE](state, bool) {
    state.videoActive = bool;
  },
  [types.SET_VIDEO_READY](state, bool) {
    state.videoReady = bool;
  },
  [types.SET_VIDEO_PLAYING](state, bool) {
    state.videoPlaying = bool;
  },
  [types.SET_VIDEO_COMPLETE_PERCENT](state, val) {
    state.videoCompletePercent = val;
  },
};

const actions = {
  setVideoRender({ commit }, bool) {
    commit(types.SET_VIDEO_RENDER, bool);
  },
  setVideoReady({ commit }, bool) {
    commit(types.SET_VIDEO_READY, bool);
  },
  setVideoIndex({ commit }, val) {
    commit(types.SET_VIDEO_INDEX, val);
  },
  setVideoPlaying({ commit }, val) {
    commit(types.SET_VIDEO_PLAYING, val);
  },
  setVideoCompletePercent({ commit }, val) {
    commit(types.SET_VIDEO_COMPLETE_PERCENT, val);
  },
  setVideoActive({ commit }, bool) {
    commit(types.SET_VIDEO_ACTIVE, bool);
  },
  setResetVideo({ commit }, bool) {
    commit(types.SET_RESET_VIDEO, bool);
  },
};

export default {
  namespaced: true,
  state: moduleState,
  getters,
  mutations,
  actions,
};
