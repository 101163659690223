<template>
  <div class="modal-carousel">
    <div class="modal-carousel__controls">
       <PulseButton
        class="control control--close"
        :iconOnly="true"
        color="black-inverted"
        :icon="close"
        @click="handleCloseClick"
      />
      <PulseButton
        class="control control--prev"
        color="black-inverted"
        :iconOnly="true"
        :icon="arrow"
        @click="handlePrevClick"
        v-if="activeIndex !== 0 && content.images.length > 1"
      />
      <PulseButton
        class="control control--next"
        color="black-inverted"
        :iconOnly="true"
        :icon="nextIcon"
        @click="handleNextClick"
        v-if="content.images.length > 1"
      />
    </div>
    <div class="modal-carousel__wrapper" :class="{ visible }">
      <transition :name="transitionName"
        @after-enter="checkStartAuto"
      >
        <img
          :src="content.images[activeIndex]"
          :key="content.images[activeIndex]"
          alt=""
          class="modal-carousel__image"
          @load="visible = true;"
        >
      </transition>
    </div>
    <div class="modal-carousel__audio" v-if="content.audio">
      <AudioWave
        :audioFile="content.audio.audio"
        @ended="handleAudioEnd"
        @timeupdate="handleAudioTimeUpdate"
        @audioLoaded="checkPlayAudio"
        ref="audioWave"
        v-if="content.audio.audio"
      />
      <FillButton
        :copy="audioPlaying
          ? globalContent.pause
          : audioStarted
          ? globalContent.resume : globalContent.listen"
        :icon="audioPlaying ? pauseIcon : playIcon"
        :color="color"
        :type="audioPlaying ? 'pause' : 'play'"
        v-if="content.audio.audio"
        @click="handleClickAudio"
      />
    </div>
    <CaptionBar class="modal-carousel__caption" ref="captionBar" v-if="content.audio.audio" />
    <div class="modal-carousel__dots">
      <div
        v-for="(item, i) in content.images"
        class="modal-carousel__dots__dot"
        @click="handleDotClick(i)"
        @keydown.enter="handleDotClick(i)"
        :class="[color, {
          active: i === activeIndex,
          hideAuto: stopAuto,
        }]"
        :key="item"
      >
      <svg class="circle" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="9" stroke="white" stroke-opacity="0.4"/>
      </svg>
      <svg
        class="circle circle--progress"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        :style="i === activeIndex ? progressStyle : {}"
      >
        <circle cx="10" cy="10" r="9" stroke="white" stroke-opacity="0.4"/>
      </svg>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { inject } from 'vue';
import { mapGetters, mapState } from 'vuex';
import Arrow from '@/assets/svg/downArrow.svg';
import Close from '@/assets/svg/close.svg';
import Paused from '@/assets/svg/paused.svg';
import Playing from '@/assets/svg/playing.svg';
import { COLOR_BY_INDEX } from '@/core/constants';
import AudioWave from './AudioWave.vue';
import PulseButton from './PulseButton.vue';
import CaptionBar from './CaptionBar.vue';
import FillButton from './FillButton.vue';

export default {
  name: 'ModalCarousel',
  components: {
    Arrow,
    Close,
    AudioWave,
    PulseButton,
    CaptionBar,
    Playing,
    Paused,
    FillButton,
  },
  props: {
    content: {
      required: true,
    },
  },
  data: () => ({
    activeIndex: 0,
    transitionName: 'fade-slow',
    audioStarted: false,
    audioPlaying: false,
    isFirstPlay: false,
    userInteracted: false,
    start: 0,
    stopAuto: false,
    playStartTime: 0,
    slideDuration: 5,
    progressPerc: 0,
    showAuto: true,
    arrow: Arrow,
    close: Close,
    visible: false,
  }),
  computed: {
    ...mapGetters(['getContentByPath', 'displayIndex']),
    ...mapState(['activeSceneIndex']),
    globalContent() {
      return this.getContentByPath('app.global');
    },
    trackingLabel() {
      return `/devotion/${this.displayIndex}/scene/${this.displayIndex}`;
    },
    color() {
      return COLOR_BY_INDEX[this.activeSceneIndex];
    },
    playIcon() {
      return Paused;
    },
    pauseIcon() {
      return Playing;
    },
    nextIcon() {
      return this.activeIndex === this.content.images.length - 1 ? Close : Arrow;
    },
    progressStyle() {
      return {
        strokeDashoffset: 60 - (60 * (this.progressPerc / 100)),
      };
    },
  },
  watch: {
    activeIndex() {
      this.progressPerc = 0;
    },
  },
  setup() { return { tracking: inject('tracking') }; },
  mounted() {
    document.addEventListener('keydown', this.handleKeyDown);
    if (!this.content.audio.audio) this.$nextTick(this.startAutoProgress);
  },
  methods: {
    handleDotClick(i) { this.activeIndex = i; },
    handlePrevClick() { this.handleClick('back'); },
    handleNextClick() { this.handleClick(); },
    handleCloseClick() {
      this.$emit('close');
    },
    handleKeyDown({ code, repeat }) {
      if (repeat) return;
      if (code === 'ArrowRight') this.handleNextClick();
      if (code === 'ArrowLeft') this.handlePrevClick();
      if (code === 'Escape') this.$emit('close');
    },
    checkStartAuto() {
      this.startAutoProgress();
    },
    startAutoProgress() {
      if (this.stopAuto) return;
      this.startTime = Date.now();
      this.playStartTime = 0;
      this.updateProgress();
    },
    updateProgress() {
      if (this.stopAuto) return;
      const elapsedTime = (Date.now() - this.startTime) / 1000;
      const currentTime = elapsedTime + this.playStartTime;
      if (currentTime <= this.slideDuration) {
        this.animationId = requestAnimationFrame(this.updateProgress);
        this.progressPerc = (currentTime / this.slideDuration) * 100;
      } else {
        cancelAnimationFrame(this.animationId);
        this.handleClick('forward', true);
      }
    },
    handleClick(direction = 'forward', isAuto = false) {
      const { activeIndex, content } = this;
      if (isAuto && activeIndex === this.content.images.length - 1) {
        if (this.audioPlaying) {
          this.stopAuto = true;
        } else {
          this.$emit('close');
        }
        return;
      }
      if (!isAuto) {
        this.userInteracted = true;
      }
      if (direction === 'forward') {
        if (this.activeIndex === content.images.length - 1) {
          if (!isAuto) this.tracking.trackEvent('e-carousel-close', { eventLabel: this.trackingLabel });
          this.$emit('close');
        } else {
          if (!isAuto) this.tracking.trackEvent('e-carousel-next', { eventLabel: this.trackingLabel });
          this.activeIndex = (activeIndex + 1);
        }
      } else {
        if (!isAuto) this.tracking.trackEvent('e-carousel-previous', { eventLabel: this.trackingLabel });
        this.activeIndex = activeIndex - 1;
      }
      if (!isAuto) {
        cancelAnimationFrame(this.animationId);
        this.stopAuto = true;
      }
    },
    checkPlayAudio() {
      if (this.content.audio && !this.firstPlay) {
        this.firstPlay = false;
        this.handleClickAudio();
        this.startAutoProgress();
      }
    },
    handleAudioTimeUpdate(time) {
      this.$refs.captionBar.handleTimeUpdate(time);
    },
    handleAudioEnd() {
      this.audioStarted = false;
      this.audioPlaying = false;
      this.$refs.captionBar.clearCaptions();
      if (
        !this.userInteracted
        && this.activeIndex === this.content.images.length - 1
        && this.stopAuto
      ) {
        this.$emit('close');
      }
    },
    stopAudio() {
      this.$refs.audioWave.pause();
      this.audioPlaying = false;
    },
    fadeAudio() {
      if (this.content.audio.audio) {
        this.$refs.audioWave.fade();
        this.audioPlaying = false;
      }
    },
    handleClickAudio() {
      if (!this.audioStarted) {
        this.$refs.captionBar.initCaptions(this.content.audio.caption);
        this.audioStarted = true;
      } else {
        this.stopAuto = true;
        this.userInteracted = true;
      }
      if (this.audioPlaying) {
        this.$refs.audioWave.pause();
        this.audioPlaying = false;
        cancelAnimationFrame(this.animationId);
        this.animationId = null;
        this.stopAuto = true;
      } else {
        this.$refs.audioWave.play();
        this.audioPlaying = true;
      }
    },
  },
};
</script>

<style lang="scss">
.modal-carousel {
  width: 100%;
  height: 100%;
  max-height: 75vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  @include bpLarge {
    max-height: 100%;
  }
  &__caption {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 600px;
    @include bpMedium {
      bottom: 15px;
    }
  }
  &__audio {
    position: absolute;
    bottom: 90px;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .audio-wave {
      margin: 15px 0;
    }
  }
  &__dots {
    position: absolute;
    display: flex;
    z-index: 2;
    left: 50%;
    bottom: -30px;
    transform: translate(-50%, 0);
    &__dot {
      position: relative;
      padding: 6px;
      transition: background 200ms $easeOutMaterial, transform 200ms $easeOutMaterial;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:not(.active) {
        &:hover  {
          &::before {
            transform: scale(1.5);
          }
        }
      }
      .circle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 200ms $easeOutMaterial;
        &--progress {
          transform: rotate(-90deg);
          stroke-dasharray: 60;
          stroke-dashoffset: 60;
        }
      }
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background: rgba(white, 0.4);
        border-radius: 50%;
        transition: background 200ms $easeOutMaterial, transform 200ms $easeOutMaterial;
      }
      &.active {
        .circle {
          opacity: 1;
        }
        &::before {
          transform: scale(1.5);
        }
        &.red { &::before { background: $red; } }
        &.blue { &::before { background: $blue; } }
        &.teal { &::before { background: $teal; } }
        &.orange { &::before { background: $orange; } }
      }
      &.hideAuto {
        .circle {
          opacity: 0;
        }
      }
    }
  }
  &__wrapper {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 450ms $easeOutMaterial;
    &.visible { opacity: 1; }
  }
  &__image {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
  }
  &__controls {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(90deg,
     rgba(0, 0, 0, 0.2) 0%,
     rgba(0, 0, 0, 0) 7.33%,
     rgba(0, 0, 0, 0.0865102) 91.35%,
     rgba(0, 0, 0, 0.2) 100%);
    .control {
      position: absolute;
      top: 50%;
      left: 20px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      &--next {
        left: auto;
        right: 20px;
        transform: translateY(-50%) rotate(-45deg);
        .pulse-button__icon {
          transform: rotate(-45deg);
          opacity: 1;
        }
      }
      &--prev {
        transform: translateY(-50%) rotate(45deg);
        .pulse-button__icon {
          transform: rotate(45deg);
          opacity: 1;
        }
      }
      &--close {
        padding: 0 9px;
        top: 20px;
        left: auto;
        right: 20px;
        background: none;
        transform: rotate(-45deg);
        .pulse-button__icon {
          transform: rotate(45deg);
          fill: white;
        }
      }
    }
  }
}
</style>
