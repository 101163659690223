import set from 'lodash/set';
import { SET_APP_COPY } from '@/store/mutationTypes';

function getCopy(data = {}) {
  const out = {};

  Object.getOwnPropertyNames(data)
    .forEach((name) => {
      set(out, name, data[name] && data[name].value);
    });

  return out;
}

export default class {
  constructor({ store }) {
    this.buildStore = this.buildStore.bind(this);
    this.store = store;
    this.buildStore();
  }

  async buildStore(lang = 'en-us') {
    const newLangConfig = await import(`../assets/languages/${lang}.json`);
    const copy = getCopy(newLangConfig.default);
    this.store.commit(SET_APP_COPY, copy);
  }
}
