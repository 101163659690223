<template>
  <button
    class="fill-button"
    @click="$emit('click')"
    :class="[color, { icon: iconOnly }]"
    :aria-label="copy"
  >
    <span class="fill-button__copy">{{copy}}</span>
    <span v-html="icon" class="fill-button__icon" v-if="icon" :class="[type]" />
  </button>
</template>

<script>
export default {
  name: 'FillButton',
  props: {
    copy: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    icon: {
      default: null,
    },
    iconOnly: {
      default: false,
    },
    color: {
      default: 'red',
    },
  },
};
</script>

<style lang="scss">
.fill-button {
  height: 48px;
  min-width: 112px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  border: none;
  position: relative;
  background: none;
  color: white;
  text-transform: uppercase;
  line-height: 1px;
  overflow: visible;
  backface-visibility: hidden;
  transition: color 250ms $easeOutMaterial;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  &__copy {
    font-size: 12px;
    margin-top: -1px;
  }
  &.icon {
    min-width: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    .fill-button__icon {
      margin: 0;
    }
    &::before {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    &::after, &::before {
      width: 100%;
      height: 100%;
    }
    @include on-hover {
      &:after {
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%) scale(0.8);
      }
      &::before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }
  @include on-hover {
    &::after {
      width: calc(100% - 10px);
      height: calc(100% - 8px);
      opacity: 1;
    }
    &::before {
      width: 100%;
      height: 100%;
    }
  }
  &.red, &.landing-red { &::before { background-image: url('../assets/images/red-texture.png'); } }
  &.blue { &::before { background-image: url('../assets/images/blue-texture.png'); } }
  &.teal { &::before { background-image: url('../assets/images/teal-texture.png'); } }
  &.orange { &::before { background-image: url('../assets/images/orange-texture.png'); } }
  &.landing-red {
    &::after {
      outline: 1px solid $red;
      opacity: 1;
    }
    @include on-hover {
      &::after { outline-color: white; }
      .fill-button__icon { transform: translateX(5px); }
    }
  }
  &::before, &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-position: center;
    transition: width 250ms $easeOutMaterial,
                height 250ms $easeOutMaterial,
                opacity 250ms $easeOutMaterial,
                transform 250ms $easeOutMaterial,
                outline-color 250ms $easeOutMaterial;
  }
  &::before {
    background-blend-mode: multiply;
    z-index: -1;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
  }
  &::after {
    outline: 1px solid white;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 1;
    transform: translate(-50%, -50%);
  }
  &__icon {
    margin-left: 10px;
    transition: transform 250ms $easeOutMaterial;
    stroke: white;
    svg {
      transition: stroke 250ms $easeOutMaterial;
    }
    width: 8px;
    &.pause {
      width: 5px;
      right: 25px;
    }
    &.slant {
      width: 10px;
      top: 51%;
      opacity: 1;
    }
  }
}
</style>
