<template>
  <div class="end-card" id="endCardWrapper">
    <div class="end-card__landing-content">
      <transition name="fade-quick">
        <div class="end-card__landing-copy" v-if="!activeBottle">
          <h3>{{content.headline}}</h3>
        </div>
      </transition>
      <transition name="fade-quick">
        <div class="end-card__select" v-if="!activeBottle" v-html="SelectSvg" />
      </transition>
      <transition name="fade-quick">
        <Logo class="end-card__logo" v-if="!activeBottle" />
      </transition>
      <transition name="fade-quick">
        <PulseButton
          class="end-card__controls__control close"
          color="black-inverted"
          :iconOnly="true"
          :icon="Close"
          @click="handleCloseClick"
          v-if="!activeBottle"
        />
      </transition>
    </div>
    <transition name="fade-quick">
      <Spinner class="end-card__spinner" v-if="showSpinner" />
    </transition>
    <transition name="fade-quick">
      <div class="end-card__controls" v-if="activeBottle && fullBottlesGone">
        <span class="end-card__controls__control prev">
          <PulseButton
            :iconOnly="true"
            :icon="arrow"
            color="black-inverted"
            @click="handleClick(-1)"
          />
        </span>
        <span class="end-card__controls__control next">
          <PulseButton
            :iconOnly="true"
            :icon="arrow"
            color="black-inverted"
            @click="handleClick(1)"
          />
        </span>
        <button
          class="end-card__controls__control view-all"
          @click="handleViewAll"
          :aria-label="content.view_all"
        >
          <span v-html="Arrow" />
          <span class="copy">{{content.view_all}}</span>
        </button>
        <div class="end-card__controls__thumbs">
          <span v-html="ExploreSmall" />
          <div class="thumb-container">
            <button
              v-for="(bottle, i) in content.bottles"
              :key="bottle.id"
              @click="setActiveBottle(i)"
              class="end-card__controls__thumbs__thumb"
              :class="{ active: activeSlide === i }"
              :aria-label="bottle.name"
            >
              <img
                :src="`${baseURL}endScene/mobile-bottles/${bottle.id}.png`"
                :class="[bottle.id]"
                :alt="bottle.name"
              >
            </button>
          </div>
        </div>
      </div>
    </transition>
    <div class="end-card__backgrounds" ref="backgrounds">
      <img
        class="end-card__backgrounds__bg"
        v-for="(background, i) in backgrounds"
        :class="{
          visible: (activeSlide !== null && i === activeSlide % backgrounds.length),
        }"
        alt=""
        :key="`${background}--${i}`"
        :src="background"
      />
      <img
        class="end-card__backgrounds__bg video"
        :class="{
          visible: !fullBottlesGone && activeSlide === null,
        }"
        alt=""
        :src="mainBg"
        @load="handleBackgroundLoad"
      />
    </div>
    <div></div>
    <transition name="fade-quick" @after-leave="fullBottlesGone = true;">
      <div class="end-card__bottles" ref="bottleWrap" v-if="!activeBottle">
        <div
          class="end-card__bottles__bottle bottle"
          v-for="(bottle, i) in content.bottles"
          :class="`bottle--${i}`"
          :key="bottle.id"
        >
          <div class="bottle__image" :class="[bottle.id]">
            <div
              class="hit-box"
              :class="[bottle.id]"
              @click="setActiveBottle(i)"
              @keydown.enter="setActiveBottle(i)"
            />
            <img
              :src="`${baseURL}endScene/mobile-bottles/${bottle.id}.png`"
              :alt="bottle.name"
              @load="handleBottleLoad"
            >
            <div class="bottle__image__copy">
              <h4>{{bottle.name}}</h4>
              <p>{{bottle.subhead}}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="bottle-anim" :duration="{ enter: 5000, leave: 500 }">
      <div
        class="end-card__content"
        :key="activeBottle.id"
        ref="content"
        v-if="activeBottle && fullBottlesGone"
      >
        <div class="end-card__content__bottle" :class="{ portrait: isPortrait }">
          <img
            class="mobile"
            :src="`${this.baseURL}endScene/mobile-bottles/${activeBottle.id}.png`"
            :class="[activeBottle.id, { largeScreen: viewWidth >= 768 }]"
            :alt="activeBottle.name"
            v-show="isPortrait"
          />
          <img
            class="desktop"
            :src="`${this.baseURL}endScene/${activeBottle.id}.png`"
            :class="[activeBottle.id]"
            :alt="activeBottle.name"
            v-show="!isPortrait"
          />
        </div>
        <div class="end-card__content__copy">
          <h2 class="end-card__content__title title">
            {{activeBottle.name}}
          </h2>
          <p v-html="activeBottle.subhead" class="subhead" />
          <p v-html="activeBottle.copy" class="copy" />
          <div class="links">
            <a
              :href="activeBottle.link"
              target="_blank"
              rel="noopener noreferrer"
              @click="trackLinkClick"
              class="link"
              :aria-label="content.buy"
            >
              <PulseButton
                :icon="Buy"
                :copy="content.buy"
                type="slant"
                color="black-inverted"
              />
            </a>
            <a :aria-label="content.recipe_button">
              <PulseButton
                :icon="Drink"
                @click="handleShowRecipes"
                :copy="content.recipe_button"
                type="slant"
                color="black-inverted"
              />
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import gsap from 'gsap';
import { inject } from 'vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import { ASSET_BASE_URL } from '@/core/constants';
import Buy from '@/assets/svg/buy.svg';
import Drink from '@/assets/svg/drink.svg';
import Close from '@/assets/svg/close.svg';
import SelectSvg from '@/assets/svg/select.svg';
import ExploreSmall from '@/assets/svg/exploreSmall.svg';
import Arrow from '@/assets/svg/downArrow.svg';
import seventy from '@/assets/svg/seventy.svg';
import ultima from '@/assets/svg/ultima.svg';
import fourtytwo from '@/assets/svg/fourtytwo.svg';
import blanco from '@/assets/svg/blanco.svg';
import reposado from '@/assets/svg/reposado.svg';
import anejo from '@/assets/svg/anejo.svg';
import PulseButton from './PulseButton.vue';
import Logo from './Logo.vue';
import RecipeTabs from './RecipeTabs.vue';
import Spinner from './Spinner.vue';

export default {
  name: 'EndScene',
  components: {
    PulseButton,
    Logo,
    RecipeTabs,
    Spinner,
  },
  props: {
    animIn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    Arrow,
    Close,
    ExploreSmall,
    SelectSvg,
    baseURL: ASSET_BASE_URL,
    activeSlide: null,
    dragging: false,
    bottlesLoaded: 0,
    backgroundLoaded: false,
    activeRecipes: null,
    fullBottlesGone: false,
    showSpinner: false,
    hasAnimedIn: false,
    dir: '',
    logos: {
      seventy,
      blanco,
      anejo,
      reposado,
      ultima,
      fourtytwo,
    },
    Drink,
    Buy,
    backgrounds: [
      `${ASSET_BASE_URL}endScene/backgrounds/bgBlanco.jpg`,
      `${ASSET_BASE_URL}endScene/backgrounds/bgReposado.jpg`,
      `${ASSET_BASE_URL}endScene/backgrounds/bgAnejo.jpg`,
      `${ASSET_BASE_URL}endScene/backgrounds/bgCristalino.jpg`,
      `${ASSET_BASE_URL}endScene/backgrounds/bg1942.jpg`,
      `${ASSET_BASE_URL}endScene/backgrounds/bgUltima.jpg`,
    ],
  }),
  computed: {
    ...mapGetters(['getContentByPath', 'isMobile']),
    mainBg() {
      return this.isMobile
        ? `${ASSET_BASE_URL}images/intro_bg-small.jpg`
        : `${ASSET_BASE_URL}images/intro_bg.jpg`;
    },
    content() {
      return this.getContentByPath('app.end_scene');
    },
    bottleBaseURL() {
      return this.viewWidth < 769 ? `${this.baseURL}endScene/mobile-bottles` : `${this.baseURL}endScene`;
    },
    arrow() {
      return Arrow;
    },
    activeBottle() {
      if (!this.activeSlide === null) return null;
      return this.content.bottles[this.activeSlide];
    },
    overlay() {
      return `${ASSET_BASE_URL}endScene/vignette.png`;
    },
    currentByWindowSize() {
      if (this.viewWidth >= 1441) return '35%';
      if (this.viewWidth > 767) return '25%';
      return '50%';
    },
    topByWindowSize() {
      if (this.viewWidth > 767) return '50%';
      return '30%';
    },
    isPortrait() {
      return this.viewWidth < this.viewHeight;
    },
    displaySlide() {
      return this.activeSlide ? this.activeSlide + 1 : null;
    },
    canAnimIn() {
      return this.backgroundLoaded && this.bottlesLoaded >= this.content.bottles.length - 1;
    },
    ...mapState(['viewWidth', 'viewHeight']),
  },
  watch: {
    canAnimIn(val) {
      if (!val) return;
      this.hasAnimedIn = true;
      this.showSpinner = false;
      this.animEndCardIn();
    },
  },
  setup() { return { tracking: inject('tracking') }; },
  mounted() {
    this.slides = Array.from(this.$refs.bottleWrap.querySelectorAll('.bottle'));
    document.addEventListener('keydown', this.handleKey);
    this.spinnerTimeout = setTimeout(() => {
      if (!this.hasAnimedIn) this.showSpinner = true;
      if (this.isMobile) {
        const tl = gsap.timeline();
        tl.to(this.$refs.bottleWrap, { scrollLeft: 100, ease: 'power2.out', duration: 0.6 });
        tl.to(this.$refs.bottleWrap, { scrollLeft: 0, ease: 'power2.out', duration: 0.6 });
      }
    }, 1000);
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.handleKey);
    clearTimeout(this.spinnerTimeout);
  },
  methods: {
    ...mapActions(['setActiveRecipes']),
    handleCloseClick() {
      this.$emit('close');
    },
    trackLinkClick() {
      this.tracking.trackEvent('e-bottle-scene-tequila1-1', { eventAction: this.activeBottle.name });
    },
    handleKey({ code, repeat }) {
      if (repeat) return;
      if (code === 'ArrowRight') this.handleClick(1);
      if (code === 'ArrowLeft') this.handleClick(-1);
    },
    setActiveBottle(id) {
      this.activeSlide = id;
      this.tracking.trackEvent('e-bottle-scene-tequila1', { eventLabel: `/tequilas/${id + 1}`, eventAction: `View ${id + 1}` });
      this.tracking.trackEvent('p-tequilas-bottle', { pagePath: `/tequilas/${id + 1}` });
    },
    handleShowRecipes() {
      this.tracking.trackEvent('e-bottle-scene-tequila1-2', { eventAction: `View Our Recipes - ${this.displaySlide}` });
      this.setActiveRecipes({ recipes: this.activeBottle.recipes, name: this.activeBottle.id });
    },
    handleBackgroundLoad() {
      if (this.hasAnimedIn) return;
      this.backgroundLoaded = true;
    },
    handleBottleLoad() {
      this.bottlesLoaded += 1;
    },
    handleViewAll() {
      this.activeSlide = null;
      this.fullBottlesGone = false;
      this.tracking.trackEvent('p-tequilas');
      this.$nextTick(() => {
        this.slides = Array.from(this.$refs.bottleWrap.querySelectorAll('.bottle'));
        gsap.to(this.slides, {
          opacity: 1,
          ease: 'power2.easeOut',
          duration: 0.5,
          stagger: 0.1,
          delay: 0.5,
        });
      });
    },
    animEndCardIn() {
      this.tracking.trackEvent('p-tequilas');
      gsap.to(['.end-card__landing-content', '.end-card__backgrounds', '.end-card'], {
        opacity: 1,
        duration: 0.3,
        ease: 'power2.out',
        pointerEvents: 'all',
        onComplete: () => {
          gsap.fromTo(this.slides, { opacity: 0 }, {
            opacity: 1,
            ease: 'power2.easeOut',
            duration: 0.5,
            stagger: 0.1,
          });
        },
      });
    },
    animCopyIn() {
    },
    handleClick(inc) {
      this.dir = inc < 0 ? 'prev' : 'next';
      const label = inc < 0 ? 'e-bottle-scene-previous' : 'e-bottle-scene-next';
      if (this.activeSlide !== 0 || this.dir === 'next') {
        this.activeSlide = (this.activeSlide + inc) % this.slides.length;
      } else {
        this.activeSlide = this.slides.length - 1;
      }
      this.tracking.trackEvent(label);
      this.tracking.trackEvent('p-tequilas-bottle', { pagePath: `/tequilas/${this.displaySlide}` });
    },
  },
};
</script>

<style lang="scss">
.end-card {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  max-height: 1100px;
  max-width: 2000px;
  transform: translate(-50%, -50%);
  &__logo {
    position: absolute;
    top: 10px;
    left: 50%;
    width: auto;
    z-index: 1000;
    opacity: 1;
    transform: translate(-50%, 0);
    padding: 0;
    @media screen and (max-height: 768px) and (min-width: 1024px) {
      width: 85px;
      top: 5px;
    }
    .logo__logo {
      width: 100px;
      height: 75px;
    }
  }
  &__landing-content {
    opacity: 0;
  }
  &__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__landing-copy {
    position: absolute;
    left: 50%;
    top: 120px;
    width: 100%;
    transform: translate(-50%, 0);
    z-index: 10;
    text-align: center;
    @media screen and (max-height: 768px) and (min-width: 1024px) {
      top: 80px;
    }
    p {
      font-size: 14px;
    }
    h3 {
      line-height: 1;
      @media screen and (max-height: 768px) and (min-width: 1024px) {
        font-size: 2.6rem;
      }
    }
  }
  &__explore, &__select {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    z-index: 5;
  }
  &__select {
    bottom: 50px;
    top: auto;
    max-width: 300px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 100%;
    }
    @include bpMedium {
      bottom: 80px;
      @media screen and (max-height: 850px) { bottom: 50px; }
      @media screen and (max-height: 800px) { bottom: 30px; }
    }
    @media screen and (max-width: 375px) { bottom: 15px; }
  }
  &__backgrounds, &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 101%;
      object-fit: cover;
      opacity: 0;
      transition: opacity 250ms $easeOutMaterial;
      &.visible {
        opacity: 1;
      }
    }
  }
  &__overlay {
    z-index: 3;
    opacity: 0.4;
  }
  &__content {
    position: absolute;
    transform: translate(-50%, -0%);
    z-index: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    top: auto;
    width: 100%;
    height: 100%;
    left: 50%;
    flex-direction: column;
    pointer-events: none;
    @media screen and (min-width: 768px) {
      width: 80%;
      flex-direction: row;
    }
    @include bpLarge {
      width: 70%;
      max-width: 900px;
    }
    &__copy {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 15%;
      pointer-events: none;
      @media screen and (max-width: 375px) {
        margin-top: 6%;
      }
      @media screen and (min-width: 768px) {
        margin-top: 0%;
        max-width: 500px;
        width: 50%;
        margin-left: -10%;
      }
      .links {
        display: flex;
        pointer-events: initial;
        a {
          margin: 0 6px;
          @include bpMedium {
            margin: 0 13px;
          }
          -webkit-tap-highlight-color: transparent;
          .pulse-button {
            font-size: 12px;
            white-space: nowrap;
            height: 38px;
            @include on-hover {
              .pulse-button__icon {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
    &__bottle {
      width: 100%;
      height: 30%;
      max-width: 500px;
      position: relative;
      margin-top: 5%;
      &:not(.portrait) {
        margin-top: 0%;
        margin-left: -10%;
        max-width: 70%;
        min-width: 400px;
        height: 100%;
      }
      @include bpLarge {
        width: 75%;
        max-width: 575px;
      }
      img {
        position: absolute;
        width: 65%;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        &.mobile {
          &.largeScreen {
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-60%, -50%);
            &.blanco, &.seventy { width: 130%; transform: translate(-60%, -45%); }
            &.ultima, &.fourtytwo {
              width: 60%;
            }
          }
        }
        @media screen and (max-height: 760px) {
          width: 50%;
        }
        @media screen and (max-width: 325px) {
          width: 50%;
        }
        @media screen and (min-width: 769px) {
          top: auto;
          left: 50%;
          bottom: 0;
          z-index: -1;
          width: 140%;
          max-width: 670px;
          transform: translate(-25%, 0);
        }
        @media screen and (max-height: 850px) {
          width: auto;
          max-height: 70vh;
        }
        @include bpLarge {
          transform: translateX(-22%);
        }
        &.blanco, &.seventy {
          bottom: 3%;
          left: 47.5%;
          @media screen and (max-width: 768px) {
            width: 90%;
            top: 75%;
            left: 50%;
          }
          @media screen and (max-height: 768px) and (max-width: 768px) {
            width: auto;
            top: 60%;
            max-height: 40vh;
          }
          @media screen and (max-height: 600px) and (max-width: 768px) { max-height: 34vh; }
        }
        &.seventy {
          @include bpLarge {
            left: 50.5%;
            bottom: 2.5%;
          }
          @media screen and (max-width: 768px) {
            top: 78%;
            left: 51%;
          }
          @media screen and (max-height: 555px) {
            top: 70%;
          }
        }
        &.reposado {
          width: 95%;
          left: 47.5%;
          @media screen and (max-width: 768px) {
            width: 70%;
            top: 70%;
            left: 50%;
          }
          @media screen and (max-height: 768px) and (max-width: 768px) {
            width: auto;
            max-height: 40vh;
            top: 60%;
          }
          @media screen and (min-height: 1000px) {
            width: 120%;
            bottom: 1%;
            left: 47.5%;
          }
          @media screen and (max-height: 850px) and (min-width: 769px) {
            width: auto;
            max-height: 70vh;
          }
          @media screen and (max-height: 600px) and (max-width: 768px) { max-height: 34vh; }
        }
        &.anejo {
          bottom: -1%;
          @media screen and (max-width: 768px) {
            width: 70%;
            top: 70%;
          }
          @media screen and (max-height: 768px) and (max-width: 768px) {
            width: auto;
            top: 60%;
            max-height: 40vh;
          }
          @media screen and (max-height: 600px) and (max-width: 768px) { max-height: 34vh; }
        }
        &.ultima, &.fourtytwo {
          width: 35%;
          transform: translate(-50%, -55%);
          @media screen and (min-width: 769px) {
            min-width: 200px;
            bottom: auto;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          @media screen and (min-width: 1200px) {
           width: 40%;
          }
          @media screen and (max-height: 850px) and (min-width: 769px) {
            width: auto;
            max-height: 65vh;
            min-width: 0;
            transform: translate(-50%, -52%);
          }
          @media screen and (max-height: 768px) and (max-width: 768px) {
            width: auto;
            min-width: 0;
            top: 55%;
            max-height: 40vh;
          }
          @media screen and (max-height: 555px) and (max-width: 768px) {
            width: auto;
            min-width: 0;
            top: 55%;
            max-height: 35vh;
          }
        }
        @media screen and (min-height: 1000px) {
          max-width: 850px;
        }
      }
    }
    &__logo {
      margin: 10px 0;
      width: 50%;
      max-width: 250px;
      @media screen and (max-width: 325px) {
        width: 40%;
      }
      @media screen and (min-width: 768px) {
        width: 70%;
        max-width: 330px;
        &.seventy {
          max-width: 90%;
        }
        &.fourtytwo {
          max-width: 250px;
        }
      }
      &.seventy {
        width: 100%;
      }
    }
    p {
      color: white;
      text-transform: initial;
      margin: 0 0 15px 0;
      max-width: 356px;
      font-size: 14px;
      line-height: 21px;
      @media screen and (min-width: 768px) {
        width: 90%;
        font-size: 15px;
        margin: 0 0 20px 0;
      }
      &.subhead {
        font-size: 16px;
        line-height: 23px;
        @media screen and (min-width: 768px) {
          font-size: 18px;
          line-height: 27px;
        }
        margin: 0;
        text-transform: uppercase;
        padding-bottom: 20px;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 10px;
          height: 1px;
          width: 36px;
          background: white;
          transform: translateX(-50%);
        }
      }
    }
    a {
      text-decoration: none;
    }
  }
  &__overlay {
    z-index: 3;
  }
  &__controls {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 7;
    pointer-events: none;
    &__thumbs {
      position: absolute;
      justify-content: center;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      flex-wrap: wrap;
      max-width: 283px;
      width: 100%;
      pointer-events: all;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .thumb-container {
        display: flex;
      }
      @media screen and (min-width: 768px) {
        bottom: 30px;
      }
      svg {
        width: 100%;
        margin-bottom: 10px;
        display: none;
        @media screen and (min-width: 768px) {
          display: block;
        }
      }
      &__thumb {
        width: 40px;
        height: 40px;
        margin: 0 4px 0 0;
        background: rgba(1, 1, 1, 0.4);
        border: 1px solid rgba(white, 0.5);
        padding: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        -webkit-tap-highlight-color: transparent;
        transition: border-color 250ms $easeOutMaterial,
                    background 250ms $easeOutMaterial;
        img {
          height: 95%;
          margin-top: 5px;
          &.ultima, &.fourtytwo {
            margin-top: 0;
          }
        }
        @include on-hover {
          border-color: white;
        }
        &.active {
          border-color: white;
          background: $black;
        }
      }
    }
    &__control {
      pointer-events: initial;
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(-45deg);
      -webkit-tap-highlight-color: transparent;
      &.view-all {
        transform: rotate(0deg);
        top: 30px;
        left: 30px;
        background: none;
        border: none;
        border-bottom: 1px solid rgba(white, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        padding-bottom: 5px;
        cursor: pointer;
        .copy {
          margin-left: 15px;
          font-size: 12px;
          line-height: 18px;
          text-transform: uppercase;
        }
        svg {
          transform: rotate(90deg);
          width: 10px;
          transition: transform 250ms $easeOutMaterial;
        }
        @include on-hover {
          svg {
            transform: rotate(90deg) translateY(5px);
          }
        }
      }
      &.close {
        top: 30px;
        right: 30px;
        transform: rotate(-45deg);
        z-index: 10;
        svg {
          fill: white;
          transform: rotate(45deg)
        }
      }
      &.prev {
        left: 30px;
        @include bpLarge {
          left: 6%;
        }
        @include bpXLarge {
          left: 15%;
        }
        .pulse-button__icon {
          transform: rotate(135deg)
        }
      }
      &.next {
        right: 30px;
        @include bpLarge {
          right: 8%;
        }
        @include bpXLarge {
          right: 15%;
        }
        .pulse-button__icon {
          transform: rotate(-45deg)
        }
      }
    }
  }
  &__bottles {
    position: absolute;
    height: 90%;
    width: 100%;
    z-index: 2;
    display: flex;
    overflow: scroll;
    -webkit-tap-highlight-color: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
    @media screen and (max-width: 375px) { top: 20px; }
    @include bpLarge {
      height: 90%;
      overflow: hidden;
      justify-content: center;
      max-width: 1500px;
      min-height: 720px;
      max-height: 900px;
      left: 50%;
      transform: translateX(-50%);
    }
    @media screen and (max-height: 768px) {
      min-height: 0;
      max-width: 1075px;
    }
    &__bottle {
      opacity: 0;
      width: 100%;
      min-width: 200px;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      @include bpLarge {
        min-width: 0;
      }
      .bottle {
        &__image {
          width: 100%;
          height: 100%;
          position: absolute;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          overflow: visible;
          &__copy {
            position: absolute;
            text-align: center;
            bottom: 21%;
            transform: translateY(100%);
            z-index: 3;
            @include bpLarge {
              bottom: 20%;
            }
            @include bpXLarge {
              bottom: 21%;
            }
            h4 {
              font-size: 32px;
              line-height: 30px;
            }
            p {
              font-size: 14px;
              margin: 5px;
              white-space: nowrap;
              text-transform: none;
            }
          }
          &.blanco, &.seventy { img { bottom: 16.15%; } }
          &.fourtytwo, &.ultima { img { bottom: 19.25%; } }
          &.reposado, &.anejo { img { bottom: 16%; } }
          .hit-box {
            position: absolute;
            width: 80%;
            height: 21vw;
            left: 50%;
            transform: translateX(-50%);
            z-index: 3;
            cursor: pointer;
            &.blanco {
              max-height: 345px;
              bottom: calc(24% - 0.8vw);
              height: 31%;
              @media screen and (min-width: 768px) {
                bottom: calc(25% - 0.8vw);
                height: 33%;
              }
              @include bpLarge {
                height: 21vw;
                bottom: calc(24% - 0.8vw);
              }
              @include bpXLarge {
                bottom: 24%;
              }
            }
            &.fourtytwo, &.ultima {
              bottom: calc(23% - 0.8vw);
              height: 36vh;
              max-height: 485px;
              width: 35%;
              @media screen and (min-width: 768px) {
                width: 45%;
                bottom: calc(24% - 0.8vw);
                height: 44vh;
              }
              @include bpLarge {
                height: 30.5vw;
                bottom: calc(25% - 1.2vw);
              }
              @include bpXLarge {
                bottom: calc(25% - 0.8vw);
              }
            }
            &.reposado, &.anejo, &.seventy {
              bottom: calc(24% - 0.8vw);
              max-height: 345px;
              height: 32%;
              @media screen and (min-width: 768px) {
                bottom: calc(25% - 0.8vw);
                height: 34%;
              }
              @include bpLarge {
                height: 22vw;
                bottom: calc(25.5% - 1.8vw);
              }
              @include bpXLarge {
                bottom: calc(25.5% - 0.8vw);
              }
            }
            @include on-hover {
              ~ img {
                transform: translate(-50%, 0) scale(1.025);
              }
            }
          }
          img {
            transition: transform 350ms $easeOutMaterial;
            position: absolute;
            bottom: 20%;
            left: 50%;
            transform: translate(-50%, 0);
            width: 100%;
            pointer-events: none;
            @media screen and (max-height: 768px) {
              width: auto;
              height: 40%;
            }
          }
          &.blanco, &.seventy {
            img {
              width: 162%;
              @media screen and (max-height: 768px) {
                width: auto;
                height: 48%;
              }
            }
          }
          &.seventy {
            img {
              width: 165%;
              @media screen and (max-height: 768px) {
                width: auto;
                height: 48%;
              }
            }
          }
          &.reposado, &.anejo {
            img {
              width: 132%;
              @media screen and (max-height: 768px) {
                width: auto;
                height: 50%;
              }
            }
          }
          &.fourtytwo {
            img {
              width: 65%;
              @media screen and (min-width: 768px) {
                width: 80%;
              }
              @media screen and (max-height: 768px) {
                width: auto;
                height: 52%;
              }
              @media screen and (max-height: 600px) and (max-width: 768px) { height: 45%; }
              @media screen and (max-height: 768px) and (min-width: 1024px) { height: 65%; }
            }
          }
          &.ultima {
            img {
              width: 65%;
              @media screen and (min-width: 768px) {
                width: 77.7%;
              }
              @media screen and (max-height: 768px) {
                width: auto;
                height: 52%;
              }
              @media screen and (max-height: 600px) and (max-width: 768px) { height: 45%; }
              @media screen and (max-height: 768px) and (min-width: 1024px) { height: 65%; }
            }
          }
        }
      }
    }
  }
}
</style>
