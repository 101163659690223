/* eslint-disable no-underscore-dangle */
import debounce from 'lodash/debounce';
import fastdom from 'fastdom';
import * as types from '@/store/mutationTypes';
import { SCROLL_DIRECTION } from './constants';

const DEFAULTS = {
  speed: 50, // how fast to update
  buffer: 5, // how far to scroll before it counts
};

export default class AppScrollListeners {
  constructor(store, {
    scrollingElement = document.body,
    speed = DEFAULTS.speed,
    buffer = DEFAULTS.buffer,
  } = {}) {
    this.store = store;
    this.listeners = [];

    this._scrollingElement = scrollingElement;
    this._options = { ...DEFAULTS, speed, buffer };
    this._lastDirection = SCROLL_DIRECTION.DOWN;
    this._lastScrollY = 0;
  }

  init = () => {
    const resizeListener = debounce(
      this.updateDimensions,
      this._options.speed,
      { leading: true, trailing: true },
    );
    window.addEventListener('mousemove', this.updateMousePosition);
    window.addEventListener('resize', resizeListener);
    window.addEventListener('wheel', this.handleWheel);
    window.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    window.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    window.addEventListener('orientationchange', this.handleOrientationChange, { passive: true });
    this.listeners.push(['resize', resizeListener]);
    this.listeners.push(['mousemove', this.updateMousePosition]);
    this.listeners.push(['wheel', this.handleWheel]);
    this.listeners.push(['touchmove', this.handleTouchMove]);
    this.listeners.push(['touchstart', this.handleTouchStart]);
    this.listeners.push(['orientationchange', this.handleOrientationChange]);

    // const scrollListener = debounce(
    //   this.updateScroll.bind(this),
    //   this._options.speed,
    //   { leading: true, trailing: true },
    // );
    // window.addEventListener('scroll', scrollListener);
    // this.listeners.push(['scroll', scrollListener]);
    this.updateDimensions();
    this.handleOrientationChange();
  }

  handleWheel = (e) => {
    let direction;
    if (e.deltaY < 0) {
      direction = SCROLL_DIRECTION.DOWN;
    } else {
      direction = SCROLL_DIRECTION.UP;
    }
    this.setScrollDirection(direction);
  }

  handleTouchStart = (e) => { this._lastScrollY = e.touches[0].clientY; }

  handleOrientationChange = () => {
    setTimeout(() => {
      const isLandscape = window.matchMedia('(orientation: landscape)').matches;
      if (window.innerWidth < 1024 && isLandscape) {
        this.store.commit(types.SET_SHOW_ROADBLOCK, true);
      } else this.store.commit(types.SET_SHOW_ROADBLOCK, false);
    }, 50);
  }

  handleTouchMove = (e) => {
    let direction;
    const deltaY = this._lastScrollY - e.touches[0].clientY;

    if (deltaY < 0) {
      direction = SCROLL_DIRECTION.DOWN;
    } else {
      direction = SCROLL_DIRECTION.UP;
    }
    this.setScrollDirection(direction);
  }

  setScrollDirection = (direction) => {
    if (direction !== this.store.state.scrollDirection) {
      this.store.commit(types.SET_SCROLL_DIRECTION, direction);
    }
  }

  updateDimensions = () => {
    let clientWidth;
    let clientHeight;
    fastdom.measure(() => {
      ({ clientWidth, clientHeight } = this._scrollingElement);
    });
    fastdom.mutate(() => {
      this.store.commit(types.SET_WINDOW_SIZE, {
        dimensions: {
          width: clientWidth || 0,
          height: clientHeight || 0,
        },
      });
    });
  }

  updateMousePosition = (e) => {
    let mousePosition;
    fastdom.measure(() => {
      mousePosition = { x: e.pageX, y: e.pageY };
    });
    fastdom.mutate(() => {
      this.store.commit(types.SET_MOUSE_POSITION, { mousePosition });
    });
  }

  // checkDirection() {
  // TODO see if we need a scroll direction - MA
  // }

  destroy = () => {
    this.listeners.forEach(([event, listener]) => {
      window.removeEventListener(event, listener);
    });
  }
}
